import EditorTheme from "./themes/theme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { $generateNodesFromDOM } from "@lexical/html"; // Import the HTML-to-JSON conversion method
import { $insertNodes } from "lexical"
// import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
// import CodeHighlightPlugin from "./plugins/CodeHighLightPlugin";
// import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import './Editor.css'
import { $getRoot } from "lexical";
import HtmlGeneratorPlugin from "./plugins/HtmlGeneratorPlugin"
import NodesGeneratorPlugin from "./plugins/NodesGeneratorPlugin";

function Placeholder() {
    return <div className="editor-placeholder">Enter guidenlines here...</div>;
}

const Editor: React.FC<any> = (({ onChange, initialContent }) => {
    function prepopulatedRichText(editor:any) {
        if(!initialContent) return ;
        const root = $getRoot();
        if (root.getFirstChild() === null) {
            const parser = new DOMParser();
            const isValidJSON = (str:string) => {
                try {
                  JSON.parse(str);
                  return true;
                } catch (e) {
                  return false;
                }
              };
            const dom = parser.parseFromString(isValidJSON(initialContent) ? JSON.parse(initialContent) : initialContent, "text/html");
            const nodes = $generateNodesFromDOM(editor, dom);
            $insertNodes(nodes);
        }
    }
    
    
    const editorConfig = {
        theme: EditorTheme,
        onError: (error:any) => {
            throw error;
        },
        namespace: "editor-tokenVariable",
        editable:true,
        editorState: (editor:any) => prepopulatedRichText(editor),
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };
    return (
        <LexicalComposer
            initialConfig={editorConfig}
        >
            <div className="editor-container">

                <ToolbarPlugin />
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" />}
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HtmlGeneratorPlugin onChange={onChange} />
                    {/* <NodesGeneratorPlugin 
                        initialJSON={initialContent} 
                        // isFirstRender={isFirstRender}
                        // setIsFirstRender={setIsFirstRender}
                    /> */}
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    {/* <CodeHighlightPlugin /> */}
                    <ListPlugin />
                    <LinkPlugin />
                    {/* <AutoLinkPlugin />
                    <ListMaxIndentLevelPlugin maxDepth={7} /> */}
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                </div>
            </div>
        </LexicalComposer>
    )
})


export default Editor