import React, { useEffect, useState } from 'react';
import { Typography, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@mui/material';
import { AddButton } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { updateCompletedTask } from '../../redux/slices/upload';
import { STATUS } from '../../utils/status';
import { useParams } from 'react-router-dom';
import { setRadioEdited } from '../../redux/reducers/isEditTaskCompleted';

interface RadioActionProps {
    actionValue: any[];
    actionIndex: number;
    result: any;
    action: string;
    projectStatus: string;
    heading?: string;
    taskStatus: string;
    metaQCStatus: string;
}

export default function RadioAction({ taskStatus, actionValue, actionIndex, result, projectStatus, metaQCStatus }: RadioActionProps) {
    const [isProjectStatusCompleted, setIsProjectStatusCompleted] = useState(projectStatus && projectStatus === 'COMPLETED')
    const [isTaskDelivered, setIsTaskDelivered] = useState(taskStatus && taskStatus === 'DELIVERED')
    const [isMetaQCDone, setIsMetaQCDone] = useState(metaQCStatus && (metaQCStatus === 'READY FOR DELIVERY' || metaQCStatus === 'DELIVERED'))
    const [selectedValues, setSelectedValues] = useState<any[]>(actionValue);
    const dispatch = useDispatch<AppDispatch>();
    const { updateCompletedTaskStatus } = useSelector((state: RootState) => state.upload);
    const fetchPermissions = useSelector((state: RootState) => state.projects.permissions)
    const [currentConfig, setcurrentConfig] = useState<any>(undefined);
    const { id } = useParams();
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        setIsProjectStatusCompleted(projectStatus && projectStatus === 'COMPLETED')
    }, [projectStatus])
    
    useEffect(() => {
        setIsTaskDelivered(taskStatus && taskStatus === 'DELIVERED')
    }, [taskStatus])

    useEffect(() => {
        setIsMetaQCDone(metaQCStatus && (metaQCStatus === 'READY FOR DELIVERY' || metaQCStatus === 'DELIVERED'))
    }, [metaQCStatus])
    
    const handleChange = (valueIndex: number, selectedValue: string, optionIndex: number) => {
        if (isProjectStatusCompleted || isTaskDelivered || isMetaQCDone) return;
        const updatedValues = [...selectedValues];
        updatedValues[valueIndex] = {
            ...updatedValues[valueIndex],
            selected: [{ index: optionIndex, value: selectedValue }]
        };
        setSelectedValues(updatedValues);
        if(actionValue[valueIndex].selected.find((el: { index: number; }) => el.index === optionIndex+1)?.value === selectedValue) {
            setIsEdited(false);
        } else {
            setIsEdited(true);
        }
        dispatch(setRadioEdited(true));
    };

    const handleSave = () => {
        if (isProjectStatusCompleted || (isTaskDelivered || isMetaQCDone)) return;

        setcurrentConfig(actionIndex);
        const payload = {
            project_id: id,
            task_id: result.tasks[0].task_id,
            allocation_id: result.tasks[0].task_allocation_id,
            response_id: result.tasks[0].responses[0].id,
            action_id: actionIndex,
            action: selectedValues[0].config_details.name,
            action_value: {
                id: selectedValues[0].id,
                selected: selectedValues[0].selected
            }
        };
        dispatch(updateCompletedTask(payload));
        setIsEdited(false);
        dispatch(setRadioEdited(false));
    };

    if (actionValue?.length === 0) {
        return <Typography>No radio options available.</Typography>;
    }

    return (
        <>
            {selectedValues.map((value: any, valueIndex: number) => {
                return (
                    <div
                        key={valueIndex}
                        style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}
                    >
                        <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>
                            {value.config_details?.value?.label}:
                        </Typography>
                        {(value.selected?.length === 0 || value.selected?.[0]?.length === 0) && (
                            <Typography sx={{ fontStyle: 'italic', color: 'gray' }}>
                                No selection made.
                            </Typography>
                        )}
                        {fetchPermissions?.completing_task_action_details?.[1] === "read" && (
                            <RadioGroup
                                row
                                name={`radio-buttons-group-${actionIndex}`}
                                value={value.selected?.[0]?.value || ''}
                                {...((fetchPermissions?.completing_task_action_details?.[2] === "edit" && !isTaskDelivered && !isMetaQCDone ) && {
                                    onChange: (e) => {
                                        const selectedValue = e.target.value;
                                        const optionIndex = value.config_details?.value?.value.findIndex(
                                            (option: any) => option.value === selectedValue
                                        );
                                        handleChange(valueIndex, selectedValue, optionIndex);
                                    }
                                })}
                            >
                                {value.config_details?.value?.value.map((option: any, optionIndex: number) => (
                                    <FormControlLabel
                                        key={optionIndex}
                                        value={option.value}
                                        control={(fetchPermissions?.completing_task_action_details?.[2] === "edit" && !isTaskDelivered && !isMetaQCDone) ? <Radio /> : <Radio disabled />}
                                        label={option.value}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    </div>
                )
            })}

            {(!isProjectStatusCompleted || (!isTaskDelivered || !isMetaQCDone) ) && isEdited && (
                fetchPermissions.completing_task_action_details?.[2] === "edit" ? (
                    <AddButton
                        startIcon={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING ? <CircularProgress /> : null}
                        disabled={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING}
                        onClick={() => handleSave()}
                    >
                        Save
                    </AddButton>
                )
                    : <></>)}
        </>
    );
}
