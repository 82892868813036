import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box, SelectChangeEvent, MenuItem, Select, Checkbox, FormControlLabel, RadioGroup, Radio, FormLabel, CircularProgress, Divider, FormControl } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useParams } from 'react-router-dom';
import { createProject, setCreateProjectStatus, setUpdateProjectStatus, updateProject, fetchProjectTypes, fetchTaskCategories } from '../../redux/slices/projectSlice/addProjectSlice';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { FormField } from '../inputField/textfield';
import { UserRoleSelect } from '../inputField/selectField';
import useStyles from '../../assets/styles/style';
import { Container, CssBaseline } from '@mui/material';
import RichTextEditor from './textEditor';

interface FormState {
  id?: string;
  name: string;
  description: string;
  status: string;
  expert_guidelines: string;
  reviewer_guidelines: string;
  task_guidelines: string;
  data_category: string;
  annotation_method_id: string;
  annotation_method_name: string;
  annotation_method_value: string;
  task_category_id: string;
  ideal_task_time: string;
  auto_submit_task: boolean;
  is_custom_flow: boolean;
  // custom_flow: string;
  max_user_per_task: string;
  ideal_review_time: string;
  taskers?: string[];
  reviewers?: string[];
  task_category_value: string;
  task_category_name: string;
  reviewer_setting: "EDIT" | "PUSHBACK" | "FEEDBACK";
  review_setting: string[];
  split_screen: boolean;
}

const ProjectForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [projectDetails, setProjectDetails] = useState<FormState>({
    id: '',
    name: '',
    description: '',
    status: '',
    expert_guidelines: '',
    reviewer_guidelines: '',
    task_guidelines: '',
    data_category: '',
    annotation_method_id: '',
    task_category_id: '',
    ideal_task_time: '',
    auto_submit_task: false,
    is_custom_flow: false,
    // custom_flow: '',
    max_user_per_task: '',
    ideal_review_time: '',
    taskers: [''],
    reviewers: [''],
    reviewer_setting: "EDIT",
    annotation_method_value: '',
    annotation_method_name: '',
    task_category_value: '',
    task_category_name: '',
    review_setting: ['EDIT', 'PUSHBACK', 'FEEDBACK'],
    split_screen: false
  });
  const createStatus = useSelector((state: RootState) => state.projects.createStatus);
  const updateStatus = useSelector((state: RootState) => state.projects.updateStatus);
  const getStatus = useSelector((state: RootState) => state.projects.getStatus);
  const projectTypeOptions = useSelector((state: RootState) => state.projects.projectTypeOptions);
  const taskCategoryOptions = useSelector((state: RootState) => state.projects.taskCategoryOptions);
  const [autoSubmitTask, setAutoSubmitTask] = useState<boolean>(false);
  const [customFlow, setCustomFlow] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fetchedProject = useSelector((state: RootState) => state.projects.project as Partial<FormState>);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const path = window.location.href.includes('/addproject')
  const { getProjectTypesStatus, getTaskCategoryStatus } = useSelector((state: RootState) => state.projects)



  useEffect(() => {
    if (getStatus === STATUS.SUCESS && !path) {
      console.log({fetched: fetchedProject.reviewer_guidelines})
      setProjectDetails({
        id: fetchedProject.id || '',
        name: fetchedProject.name || '',
        description: fetchedProject.description || '',
        status: fetchedProject.status || '',
        expert_guidelines: fetchedProject.expert_guidelines || '',
        reviewer_guidelines: fetchedProject.reviewer_guidelines || '',
        task_guidelines: fetchedProject.task_guidelines || '',
        data_category: fetchedProject.data_category || '',
        task_category_id: fetchedProject.task_category_id || '',
        ideal_task_time: fetchedProject.ideal_task_time || '',
        auto_submit_task: Boolean(fetchedProject.auto_submit_task),
        is_custom_flow: Boolean(fetchedProject.is_custom_flow),
        // custom_flow: fetchedProject.custom_flow || '',
        max_user_per_task: fetchedProject.max_user_per_task || '',
        ideal_review_time: fetchedProject.ideal_review_time || '',
        annotation_method_id: fetchedProject.annotation_method_id || '',
        annotation_method_value: fetchedProject.annotation_method_value || '',
        annotation_method_name: fetchedProject.annotation_method_name || '',
        task_category_value: fetchedProject.task_category_value || '',
        task_category_name: fetchedProject.task_category_name || '',
        reviewer_setting: fetchedProject.reviewer_setting || "EDIT",
        review_setting: fetchedProject.review_setting || ['EDIT', 'PUSHBACK', 'FEEDBACK'],
        split_screen: fetchedProject.split_screen || false
      });

    }
  }, [getStatus, fetchedProject]);

  useEffect(() => {
    if (projectDetails.data_category) {
      dispatch(fetchProjectTypes({ paginate: false, data_category: projectDetails.data_category }));
    }
  }, [projectDetails.data_category, dispatch]);

  useEffect(() => {
    if (projectDetails.annotation_method_id) {
      dispatch(fetchTaskCategories({ paginate: false, annotation_method: projectDetails.annotation_method_id }));
    }
  }, [dispatch, projectDetails.annotation_method_id]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));

    if (fieldName === 'annotation_method_id') {
      const selectedProjectType = projectTypeOptions.find((option: any) => option.id === value);
      if (selectedProjectType) {
        dispatch(fetchTaskCategories({ annotation_method: selectedProjectType.id, paginate: false }));
      }
    }

    if (fieldName === 'task_category_id') {
      setProjectDetails((prevDetails) => ({
        ...prevDetails,
        task_category_id: value,
      }));
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAutoSubmitTask(event.target.checked);
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      auto_submit_task: event.target.checked
    }));
  };

  const handleCustomCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setCustomFlow(isChecked);
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      is_custom_flow: isChecked,
      custom_flow: '',
    }));
  };

  const handleCheckboxFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    setProjectDetails((prevDetails) => {
      let updatedReviewSetting = [...prevDetails.review_setting];

      if (checked) {
        updatedReviewSetting.push(value);
      } else {
        updatedReviewSetting = updatedReviewSetting.filter(setting => setting !== value);
      }

      return {
        ...prevDetails,
        review_setting: updatedReviewSetting,
      };
    });
  };


  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);

    const validateProjectDetails = (): { [key: string]: string } => {
      const errors: { [key: string]: string } = {};

      if (!projectDetails?.name?.trim()) {
        errors.name = 'Name is Required';
      }
      if (!projectDetails?.status?.trim()) {
        errors.status = 'Status is Required';
      }
      if (!projectDetails?.data_category?.trim()) {
        errors.data_category = 'Data Category is Required';
      }
      if (!projectDetails?.annotation_method_id?.trim()) {
        errors.annotation_method_id = 'Project Type is Required';
      }
      if (!projectDetails?.task_category_id?.trim()) {
        errors.task_category_id = 'Task Type is Required';
      }
      if (!projectDetails?.reviewer_guidelines) {
        errors.reviewer_guidelines = 'Reviewer Guidelines are Required';
      }
      if (!projectDetails?.expert_guidelines) {
        errors.expert_guidelines = 'Expert Guidelines are Required';
      }
      if (!projectDetails?.task_guidelines) {
        errors.task_guidelines = 'Task Guidelines are Required';
      }
      if (!projectDetails?.review_setting) {
        errors.review_setting = 'Review Settings are Required';
      }
      // if (projectDetails?.is_custom_flow && !projectDetails.custom_flow) {
      //   errors.custom_flow = 'Custom Flow Type is Required';
      // }
      return errors;
    };


    const errors = validateProjectDetails();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields.', 'warning');
      return;
    }

    const changedFields = Object.keys(projectDetails).reduce((acc, key) => {
      const fetchedValue = fetchedProject[key as keyof FormState];
      const newValue = projectDetails[key as keyof FormState];

      if (
        key in fetchedProject &&
        newValue !== fetchedValue &&
        (fetchedValue !== '' || newValue !== undefined) &&
        newValue !== ''
      ) {
        (acc as any)[key] = newValue;
      }

      return acc;
    }, {} as { [key: string]: string | boolean | string[] | undefined });

    if (projectDetails.review_setting.length === 0) {
      handleSnackbar('Select reviewer control', 'error');
      return;
    }

    if (id) {

      const error = validateProjectDetails()
      console.log(error)


      if (Object.keys(changedFields).length === 0) {
        handleSnackbar('No changes detected for update.', 'info');
        return;
      }

      dispatch(updateProject({ id, ...changedFields }));
    } else {
      const payload = {
        name: projectDetails.name,
        description: projectDetails.description,
        expert_guidelines: projectDetails.expert_guidelines,
        reviewer_guidelines: projectDetails.reviewer_guidelines,
        task_guidelines: projectDetails.task_guidelines,
        data_category: projectDetails.data_category,
        annotation_method_id: projectDetails.annotation_method_id,
        task_category_id: projectDetails.task_category_id,
        ...(projectDetails.ideal_task_time && { ideal_task_time: projectDetails.ideal_task_time }),
        ...(projectDetails.auto_submit_task && { auto_submit_task: autoSubmitTask }),
        ...(projectDetails.is_custom_flow && { is_custom_flow: customFlow }),
        // ...(projectDetails.is_custom_flow === true && { custom_flow: projectDetails.custom_flow }),
        ...(projectDetails.max_user_per_task && { max_user_per_task: projectDetails.max_user_per_task }),
        ...(projectDetails.ideal_review_time && { ideal_review_time: projectDetails.ideal_review_time }),
        status: projectDetails.status,
        review_setting: projectDetails.review_setting,
        split_screen: projectDetails?.split_screen
      };

      dispatch(createProject(payload));
    }
  };


  useEffect(() => {
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Project Updated Successfully', 'success');
        // setTimeout(() => {
        //   navigate('/projects');
        // }, 1000);
        window.location.reload();
      }

      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();
    return () => {
      if (updateStatus === STATUS.SUCESS) {
        dispatch(setUpdateProjectStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus, navigate, dispatch]);

  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        handleSnackbar('Project Added Successfully', 'success');
        setTimeout(() => {
          navigate('/projects');
        }, 1000);
      }
      if (createStatus === STATUS.FAILED) {
        handleSnackbar('Project already exists.', 'warning');
      }
    }
    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(setCreateProjectStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectDetails((prevState) => ({
      ...prevState,
      split_screen: event.target.value === 'splitScreen'
    }));
  };

  const handleExpertGuidlines = (newContentHTML:string) => {
    setProjectDetails((prevState) => ({
      ...prevState,
      expert_guidelines: newContentHTML,
    }));
  };  

  const handleReviewerGuidlines = (newContentHTML:string) => {
    setProjectDetails((prevState) => ({
      ...prevState,
      reviewer_guidelines: newContentHTML,
    }));
  };  

  const handleTaskerGuidlines = (newContentHTML:string) => {
    setProjectDetails((prevState) => ({
      ...prevState,
      task_guidelines: newContentHTML,
    }));
  };  

  console.log({projectDetails})

  return (
    <Box className={classes.formContainer}>
      <div>
        <div style={{ display: 'flex', marginLeft: '20px' }}>
          <FormField
            label="Project Name"
            placeholder="Enter Project Name"
            name="name"
            value={projectDetails.name}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.name ? formErrors.name : ''}
            textFieldSx={{ marginRight: '20px' }}
          />
          <FormField
            label="Project Description"
            placeholder="Enter Project Description"
            name="description"
            value={projectDetails.description}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.description ? formErrors.description : ''}
            textFieldSx={{ marginRight: '20px' }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Project Status</Typography>
            <Select
              name="annotation_method_id"
              value={projectDetails.status}
              onChange={(e) => handleSelectFieldChange(e, 'status')}
              error={formSubmitted && !!formErrors.status}
              size='small'
              displayEmpty
            >
              <MenuItem value="" disabled>Select Project Type</MenuItem>
              <MenuItem value="DRAFT">Draft</MenuItem>
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="COMPLETED">Completed</MenuItem>
              <MenuItem value="PUBLISHED">Published</MenuItem>
            </Select>
            {formSubmitted && formErrors.status && (
              <Typography variant="body2" color="error">
                {formErrors.status}
              </Typography>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1rem', marginLeft: '20px' }}>
          <UserRoleSelect
            label='Data Category'
            value={projectDetails.data_category}
            onChange={(e) => handleSelectFieldChange(e, 'data_category')}
            options={[
              { value: 'TEXT', label: 'Text' },
              { value: 'IMAGE', label: 'Image' },
              { value: 'AUDIO', label: 'Audio' },
              { value: 'VIDEO', label: 'Video', coming_soon: true },
            ]}
            error={formSubmitted && formErrors.data_category ? formErrors.data_category : ''}
            typographySx={{ marginTop: '20px' }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', marginLeft: '20px', width: '30%' }}>
            <Typography sx={{ marginTop: '20px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Project Type</Typography>
            <Select
              name="annotation_method_id"
              value={projectDetails.annotation_method_id}
              onChange={(e) => handleSelectFieldChange(e, 'annotation_method_id')}
              error={formSubmitted && !!formErrors.annotation_method_id}
              size='small'
              displayEmpty
              disabled={!projectDetails.data_category}
            >
              <MenuItem value="" disabled>Select Project Type</MenuItem>
              {(getProjectTypesStatus === STATUS.SUCESS ? projectTypeOptions : [{ id: projectDetails.annotation_method_id, value: projectDetails.annotation_method_value, name: projectDetails.annotation_method_name }]).map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {formSubmitted && formErrors.annotation_method_id && (
              <Typography variant="body2" color="error">
                {formErrors.annotation_method_id}
              </Typography>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ marginTop: '20px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Task Type</Typography>
            <Select
              name="task_category_id"
              value={projectDetails.task_category_id}
              onChange={(e) => handleSelectFieldChange(e, 'task_category_id')}
              error={formSubmitted && !!formErrors.task_category_id}
              size='small'
              displayEmpty
              disabled={!projectDetails.annotation_method_id}
            >
              <MenuItem value="" disabled>Select Task Type</MenuItem>
              {(getTaskCategoryStatus === STATUS.SUCESS ? taskCategoryOptions : [{ id: projectDetails.task_category_id, value: projectDetails.task_category_value, name: projectDetails.task_category_name }]).map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {formSubmitted && formErrors.task_category_id && (
              <Typography variant="body2" color="error">
                {formErrors.task_category_id}
              </Typography>
            )}
          </div>
        </div>

        <div style={{ display: 'flex', marginLeft: '20px' }}>
          <FormField
            label="Task Time (in mins)"
            placeholder="Enter Task Time"
            name="ideal_task_time"
            value={projectDetails.ideal_task_time}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.ideal_task_time ? formErrors.ideal_task_time : ''}
            textFieldSx={{ marginRight: '20px' }}
          />
          <FormField
            label="Review Time (in mins)"
            placeholder="Enter Review Time"
            name="ideal_review_time"
            value={projectDetails.ideal_review_time}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.ideal_review_time ? formErrors.ideal_review_time : ''}
            textFieldSx={{ marginRight: '20px' }}
          />
          <FormField
            label="Max User Per Task"
            placeholder="Enter Max User Per Task"
            name="max_user_per_task"
            value={projectDetails.max_user_per_task}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.max_user_per_task ? formErrors.max_user_per_task : ''}
          />
        </div>

        <div style={{ display: 'flex', marginLeft: '20px', marginTop: "45px", gap: 50 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={projectDetails.auto_submit_task}
                onChange={handleCheckboxChange}
                name="auto_submit_task"
                color="primary"
              />
            }

            label={<div>Auto Submit Task <Typography style={{ fontSize: "12px" }} >Automatically submits tasks upon time completion.</Typography> </div>}

            style={{ marginTop: '10px' }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={projectDetails.is_custom_flow}
                onChange={handleCustomCheckboxChange}
                name="is_custom_flow"
                color="primary"
              />
            }

            label={<div>Custom Flow<Typography style={{ fontSize: "12px" }} >Automatically get required fixed restricted flow.</Typography></div>}

            style={{ marginTop: '10px' }}
          />
        </div>

        {/* {projectDetails.is_custom_flow &&
          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', marginLeft: '20px', marginTop: "30px", }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Custom Flow Type</Typography>
            <Select
              name="custom_flow"
              value={projectDetails.custom_flow}
              onChange={(e) => handleSelectFieldChange(e, 'custom_flow')}
              error={formSubmitted && !!formErrors.custom_flow}
              size='small'
              displayEmpty
            >
              <MenuItem value="" disabled>Select Custom Flow Type</MenuItem>
              <MenuItem value="custom_flow_fsl">Custom Flow FSL</MenuItem>
            </Select>
            {formSubmitted && formErrors.custom_flow && (
              <Typography variant="body2" color="error">
                {formErrors.custom_flow}
              </Typography>
            )}
          </div>
        } */}

        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
          <FormLabel id="demo-controlled-radio-buttons-group">Reviewer Control</FormLabel>
          <Divider style={{ width: "7.5%" }} />
          <div >
            <div style={{ display: "flex", gap: "5rem" }}>

              <div>
                <FormControlLabel value="EDIT" label={<b>Reviewer edit</b>} control={<Checkbox checked={projectDetails.review_setting.includes('EDIT')} onChange={handleCheckboxFieldChange} />} />
                <Typography style={{ fontSize: "12px", marginLeft: "31px", marginTop: "-7px" }}>Reviewer can edit the response submitted by a tasker as per the need of the task.</Typography>
              </div>

              <div>
                <FormControlLabel value="PUSHBACK" label={<b>Reviewer push back</b>} control={<Checkbox checked={projectDetails.review_setting.includes('PUSHBACK')} onChange={handleCheckboxFieldChange} />} />
                <Typography style={{ fontSize: "12px", marginLeft: "31px", marginTop: "-7px" }}>Reviewer can ask the tasker to redo the defective task</Typography>
              </div>

              <div>
                <FormControlLabel value="FEEDBACK" label={<b>Reviewer feedback</b>} control={<Checkbox checked={projectDetails.review_setting.includes('FEEDBACK')} onChange={handleCheckboxFieldChange} />} />
                <Typography style={{ fontSize: "12px", marginLeft: "31px", marginTop: "-7px" }}>Reviewer can give their feedback on the task</Typography>
              </div>

            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
          <FormLabel id="demo-controlled-radio-buttons-group">User Screen</FormLabel>
          <Divider style={{ width: "5.5%" }} />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={projectDetails?.split_screen ? 'splitScreen' : 'fullScreen'}
              onChange={handleChange}
            >
              <FormControlLabel value="splitScreen" control={<Radio />} label='Split Screen' />
              <FormControlLabel value="fullScreen" control={<Radio />} label='Full Screen' />
            </RadioGroup>
          </FormControl>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: "20px" }}>
          <Typography sx={{ marginTop: '10px', marginLeft: '20px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Reviewer Guidelines</Typography>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <RichTextEditor
              onChange={handleReviewerGuidlines}
              initialContent={path ? '': fetchedProject.reviewer_guidelines} 
            />
            {formSubmitted && formErrors.reviewer_guidelines && (
              <Typography variant="body2" color="error">
                {formErrors.reviewer_guidelines}
              </Typography>
            )}
          </Container>

          <Typography sx={{ marginTop: '10px', marginLeft: '20px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Expert Guidelines</Typography>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <RichTextEditor
              onChange={handleExpertGuidlines}
              initialContent={path ? '': fetchedProject.expert_guidelines} 
            />
            {formSubmitted && formErrors.expert_guidelines && (
              <Typography variant="body2" color="error">
                {formErrors.expert_guidelines}
              </Typography>
            )}
          </Container>

          <Typography sx={{ marginTop: '10px', marginLeft: '20px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Task Guidelines</Typography>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <RichTextEditor
              onChange={handleTaskerGuidlines}
              initialContent={path ? '': fetchedProject.task_guidelines} 
            />
            {formSubmitted && formErrors.task_guidelines && (
              <Typography variant="body2" color="error">
                {formErrors.task_guidelines}
              </Typography>
            )}
          </Container>
        </div>
        <br />
        <Button
          type="button"
          onClick={(e) => handleFormSubmit(e)}
          variant="contained"
          sx={{ fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '100%', backgroundColor: '#3D85C6', borderRadius: '8px', marginTop: '20px', }}
          disabled={id ? updateStatus === STATUS.PENDING : createStatus === STATUS.PENDING}
          startIcon={id ? updateStatus === STATUS.PENDING ? <CircularProgress /> : null : createStatus === STATUS.PENDING ? <CircularProgress /> : null}
        >
          {id ? "Update Project" : "Add Project"}
        </Button>
      </div>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ProjectForm;

