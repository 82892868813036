import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import {  setGetProjectStatus } from '../../redux/slices/projectSlice/addProjectSlice';
import { STATUS } from '../../utils/status';

const ProjectListHeading = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const fetchedProject = useSelector((state: RootState) => state.projects.project);
  const getProjectStatus = useSelector((state: RootState) => state.projects.getStatus);
  const [projectName, setprojectName] = useState('')

  const handleBackToProjects = () => {
    navigate('/projects');
    setprojectName('')
    dispatch(setGetProjectStatus(STATUS.NOT_STARTED))
  };

  // useEffect(()=>{
  //   if(id){
  //     dispatch(getProject({id: id}))
  //   }
  // },[id])

  useEffect(() => {
    if(getProjectStatus === STATUS.SUCESS) {
      setprojectName(fetchedProject.name)
    }
  }, [getProjectStatus])
  

  const truncateName = (name: string): string => {
    const maxLength = 30; 
    if (name && name.length > maxLength) {
        return `${name.substring(0, maxLength)}...`;
    }
    return name;
};

  return (
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        color: '#13282A',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        height:'84px',
      }}>
        <Typography paragraph sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px', cursor: 'pointer' }} onClick={handleBackToProjects}>
        {id ? (
          <>
            <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 'inherit', cursor: 'pointer' }} />Back to Projects
          </>
          ) : (
            ""
          )}
        </Typography>
        
        <div style={{display:'flex', flexDirection:'row'}} >
          <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
            Projects
          </Typography>
          {id && (
            <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
            {getProjectStatus === STATUS.SUCESS && projectName && <KeyboardArrowRightIcon />}
              {getProjectStatus === STATUS.PENDING ? 
                <Skeleton animation={'pulse'} variant="text" sx={{ marginLeft:'5px', fontSize: '25rem', bgcolor: 'grey.100' }} /> : 
                (
                  <Tooltip title={projectName}>
                    <span style={{ cursor: 'default' }}>{truncateName(projectName)}</span>
                  </Tooltip>
                )
              }
          </Typography>
          )}
        </div>
      </Box>
    </div>
  );
}

export default ProjectListHeading;
