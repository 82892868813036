import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Paper, Typography, TextField, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { getReviewers } from '../../../redux/slices/expertSlice/addExpertSlice';
import { getProjectExperts, updateProject } from '../../../redux/slices/projectSlice/addProjectSlice';
import { STATUS } from '../../../utils/status';

interface Reviewer {
  id: string;
  email: string;
}

interface FormState {
  reviewers?: {
    expert: {
      id: string;
      email: string;
    };
  }[];
}

const InviteReviewer= () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedItems, setSelectedItems] = useState<Reviewer[]>([]);
  const [deselectedItems, setDeselectedItems] = useState<Reviewer[]>([]);
  const [items, setItems] = useState<Reviewer[]>([]);
  const [searchQueryAvailable, setSearchQueryAvailable] = useState<string>('');
  const [searchQuerySelected, setSearchQuerySelected] = useState("")


  const { id } = useParams();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const fetchedProject = useSelector((state: RootState) => state.projects.projects as Partial<FormState>);
  const [newReviewers, setNewReviewers] = useState<Reviewer[]>([]);
  const { taskers:reviewers } = useSelector((state: RootState) => state.experts)
  const { getProjectExpertsStatus, reviewExperts } = useSelector((state: RootState) => state.projects)
  const getReviewerStatus = useSelector((state: RootState) => state.experts.getTaskersStatus)

  // useEffect(() => {
  //     dispatch(getReviewers({ search: searchQueryAvailable, projectId: id || '' }))
  // }, [dispatch, searchQueryAvailable, id]);

  useEffect(() => {
    setItems(reviewers);
  }, [reviewers]);

  const getUniqueReviewers = (reviewers: Reviewer[]): Reviewer[] => {
    const uniqueReviewers: Reviewer[] = [];
    const seenEmails = new Set<string>();

    reviewers.forEach(reviewer => {
      if (!seenEmails.has(reviewer.email)) {
        uniqueReviewers.push(reviewer);
        seenEmails.add(reviewer.email);
      }
    });

    return uniqueReviewers;
  };

  const [previouslySelected, setPreviouslySelected] = useState<Reviewer[]>([]);

  const handleSelect = (item: Reviewer) => {
    // const newSelected = getUniqueReviewers([...selectedItems, item]);
    // const filteredReviewers = newSelected.filter((reviewer) =>
    //   !fetchedProject.reviewers?.some(
    //     (projectTasker: any) =>
    //       projectTasker.expert.id === reviewer.id && projectTasker.expert.email === reviewer.email
    //   )
    // );

    // setNewReviewers(filteredReviewers);
    // setPreviouslySelected(newSelected);
    // setSelectedItems(newSelected);

    // const newReviewersForPayload = filteredReviewers.length > 0 ? [filteredReviewers[0].id] : [];
    const payload: any = {
      id: id,
      email: item.email,
      assign: {
        reviewers: item.id,
      },
    };

    dispatch(updateProject(payload));
  };

  const handleDeselect = (item: any) => {
    // setSelectedItems((prevSelected) => {
    //   const newSelected = prevSelected.filter(i => i.email !== item.email);
    //   return newSelected;
    // });

    // setItems((prevItems) => {
    //   const newItems = getUniqueReviewers([...prevItems, item]);
    //   return newItems;
    // });

    const payload: any = {
      id: id,
      unassign: {
        reviewers: [item.expert_id], 
      },
    };
  
    dispatch(updateProject(payload));
  
    setDeselectedItems((prevDeselected) => {
      const newDeselected = getUniqueReviewers([...prevDeselected, item]);
      return newDeselected;
    });
  };

  const handleSearchChangeAvailable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryAvailable(event.target.value);
  };

  const handleSearchChangeSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuerySelected(event.target.value);
  };

  const debouncedAvailableSearch = useMemo(() => debounce(handleSearchChangeAvailable, 300), []);
  const debouncedSelectedSearch = useMemo(() => debounce(handleSearchChangeSelected, 300), []);

  useEffect(() => {
    return () => {
      debouncedAvailableSearch.cancel();
      debouncedSelectedSearch.cancel();
    };
  }, [debouncedAvailableSearch, debouncedSelectedSearch]);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  useEffect(() => {
    dispatch(getProjectExperts({
      type: 'REVIEW',
      paginate: false,
      is_blocked: false,
      project_id: id,
      fields: JSON.stringify(['id', 'expert.id', 'sub_project.id', 'expert.first_name', 'expert.last_name', 'expert.email', 'expert.pseudo_name', 'created_at', 'type']),
    }))
  }, [])

  const filteredReviewExperts = reviewExperts.filter((item: { expert_email: string; }) =>
    item.expert_email.toLowerCase().includes(searchQuerySelected.toLowerCase())
  );

  const isReviewerSelected = (email: string) => {
    return reviewExperts.some((reviewer: { expert_email: string; }) => reviewer.expert_email === email);
  };

  const availableReviewers = items.filter(item => !isReviewerSelected(item.email));
  const disabledReviewers = items.filter(item => isReviewerSelected(item.email));

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Paper elevation={1} sx={{ height: '40vh', backgroundColor: '#FBFBFB' }}>
            {getReviewerStatus === STATUS.PENDING ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '0' }}>
                <CircularProgress sx={{ marginTop: '40%' }} />
              </Box>
            ) : <></>}
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
              <TextField
                label={`Search Reviewers (${availableReviewers.length} reviewers available)`}
                variant="outlined"
                size="small"
                onChange={debouncedAvailableSearch}
                fullWidth
                InputProps={{
                  endAdornment: <SearchIcon onClick={toggleSearch} style={{ cursor: 'pointer' }} />,
                }}
              />
            </Box>
            {items.length === 0 && getReviewerStatus === STATUS.SUCESS && (
              <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: 'gray' }}>
                No reviewers left to invite
              </Typography>
            )}
            {getReviewerStatus === STATUS.SUCESS && (
              <List sx={{ height: 'calc(100% - 96px)', overflow: 'auto' }}>
                {availableReviewers.map(item => (
                  <ListItem
                    key={item.email}
                    button
                    onClick={() => handleSelect(item)}
                    sx={{
                      '&:hover': { backgroundColor: '#f0f0f0' },
                      cursor: 'pointer',
                    }}
                  >
                    <ListItemText primary={item.email} />
                  </ListItem>
                ))}
                {disabledReviewers.map(item => (
                  <ListItem
                    key={item.email}
                    button
                    disabled
                    sx={{
                      color: 'gray',
                      cursor: 'not-allowed',
                    }}
                  >
                    <ListItemText primary={item.email} />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
        </Grid>
        <Grid item xs={5}>
          <Paper elevation={1} sx={{ height: '40vh', backgroundColor: '#FBFBFB' }}>
            {getProjectExpertsStatus === STATUS.PENDING ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '0' }}>
                <CircularProgress sx={{ marginTop: '40%' }} />
              </Box>
            ) : <></>}
            {getProjectExpertsStatus === STATUS.SUCESS && (
              <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <TextField
                  label={`Search Reviewers (${filteredReviewExperts.length} selected)`}
                  variant="outlined"
                  size="small"
                  onChange={debouncedSelectedSearch}
                  fullWidth
                  InputProps={{
                    endAdornment: <SearchIcon style={{ cursor: 'pointer' }} />,
                  }}
                />
              </Box>
            )}
            {filteredReviewExperts.length === 0 && getProjectExpertsStatus === STATUS.SUCESS && (
              <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: 'gray' }}>
                No taskers selected
              </Typography>
            )}
            {getProjectExpertsStatus === STATUS.SUCESS && (
              <List sx={{ height: 'calc(100% - 96px)', overflow: 'auto' }}>
                {filteredReviewExperts.map((item: { expert_id: string; expert_email: string}) => (
                  <ListItem
                    key={item.expert_id}
                    button
                    onClick={() => handleDeselect(item)}
                    sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                  >
                    <ListItemText primary={item.expert_email} />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InviteReviewer;

