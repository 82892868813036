import React, { useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';

const ConfigHeading = () => {

  const navigate = useNavigate()
  const {id} = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const handleBackToConfigs = () => {
    navigate(`/project/${id}?tab=config`);
  };
  const fetchedProject = useSelector((state: RootState) => state.projects.project);
  const projectName = fetchedProject.name

  const truncateName = (name: string): string => {
    const maxLength = 30; 
    if (name && name.length > maxLength) {
        return `${name.substring(0, maxLength)}...`;
    }
    return name;
};

  useEffect(() => {
    if (id) {
      dispatch(getProject({project_id:id}));
    }
  }, [id, dispatch]);
  return (
    <>
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        color: '#13282A',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        height:'84px',
      }}>
        <Typography paragraph sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px', cursor: 'pointer' }} onClick={handleBackToConfigs}>
          <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 'inherit', cursor: 'pointer' }} /> Back to Config
        </Typography>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
          Projects <KeyboardArrowRightIcon />
          <Tooltip title={projectName}>
            <span style={{cursor:'default'}}>{truncateName(projectName)}</span>
          </Tooltip>
          <KeyboardArrowRightIcon /> Config
        </Typography>

      </Box>
    </div>
    </>
  );
}


export default ConfigHeading;
