import { Typography } from '@mui/material'
import React, { useState } from 'react'
import { ActiveProjectPaginationEntries } from '../../routes/dashboard/dashboardMain'
import ActiveProjectsList from '../Active Project List/ActiveProjectsList'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store/appStore'
import { getProjectWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard'

const ActiveProjectsOverview = () => {
  const dispatch = useDispatch<any>()
  const { getProjectWiseAnalyticsStatus, projectWiseAnalytics } = useSelector((state: RootState) => state.dashboard)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangeRowsPerPageActiveProject = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    dispatch(getProjectWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "COMPANY" }));
  };

  const handleOnChangeActiveProject = (value: number) => {
    setItemsPerPage(value)
  }

  return (
    <>
      <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', justifyContent: 'space-between' }} >
        <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>List of active projects</Typography>
        <ActiveProjectPaginationEntries
          itemsPerPage={itemsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPageActiveProject}
          handleOnChange={handleOnChangeActiveProject}
        />
      </div>
      <ActiveProjectsList
        data={projectWiseAnalytics}
        dataStatus={getProjectWiseAnalyticsStatus}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
      />
    </>
  )
}

export default ActiveProjectsOverview