import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { BulkAllocationState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/bulkAllocationExtraReducer';

const initialState: BulkAllocationState = {
  bulkAllocation: {
    data: null
  },
  taskStatus: [],
  createStatus: STATUS.NOT_STARTED,
  createTaskAnalyseStatus: STATUS.NOT_STARTED
};

export const createBulkAnalyse = createAsyncThunk(
  '/task/allocated/analyse/company/POST',
  async (payload: any, thunkAPI) => {
    const { payload: payload_updated } = payload
    const response = await api.post('/task/allocated/analyse/company/', payload_updated);
    const { status, data } = response;
    console.log({ data, status })
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const createTaskStatusAnalyse = createAsyncThunk(
  '/task/count_tasks_by_statusPOST',
  async (payload: any, thunkAPI) => {
    const { payload: payload_updated } = payload
    const response = await api.post('/task/count_tasks_by_status/', payload_updated);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const bulkAllocationSlice = createSlice({
  name: 'bulkAllocation',
  initialState,
  reducers: {
    setCreateAnalyseStatus(state, action) {
      state.createStatus = action.payload
    },
    setCreateTaskByStatus(state, action) {
      state.createTaskAnalyseStatus = action.payload
    },
  },
  extraReducers
});

export const { setCreateAnalyseStatus , setCreateTaskByStatus } = bulkAllocationSlice.actions
export default bulkAllocationSlice.reducer;
