import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { BulkAllocationState } from '../interfaces/interface';
import { createBulkAnalyse, createTaskStatusAnalyse } from '../slices/bulkAllocation/index';

const ExtraReducers = (builder: ActionReducerMapBuilder<BulkAllocationState>) => {
  builder
    .addCase(createBulkAnalyse.pending, (state) => {
      state.createStatus = STATUS.PENDING;
    })
    .addCase(createBulkAnalyse.fulfilled, (state, action: PayloadAction<any>) => {
      state.bulkAllocation = action.payload; 
      state.createStatus = STATUS.SUCESS; 
    })
    .addCase(createBulkAnalyse.rejected, (state, action) => { 
      state.createStatus = STATUS.FAILED;
    })
    .addCase(createTaskStatusAnalyse.pending, (state) => {
      state.createTaskAnalyseStatus = STATUS.PENDING;
    })
    .addCase(createTaskStatusAnalyse.fulfilled, (state, action: PayloadAction<any>) => {
      state.taskStatus = action.payload; 
      state.createTaskAnalyseStatus = STATUS.SUCESS; 
    })
    .addCase(createTaskStatusAnalyse.rejected, (state, action) => { 
      state.createTaskAnalyseStatus = STATUS.FAILED;
    })
};

export default ExtraReducers;
