import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import VideoModal from '../multiStepAllocation/videoModal';

const VendorStepHeading = () => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleBackToTasks = () => {
    const confirmLeave = window.confirm('You will lose any unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      navigate(`/project/${id}?tab=task`);
    }
  };

  const handleOpenVideoModal = () => {
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleGotItClick = () => {
    setOpenVideoModal(false);
  };

  return (
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        height:'84px',
        display:'flex',
        justifyContent:'space-between'
      }}>
        <div style={{display:'flex', flexDirection:'column', marginLeft:'13%'}}>
        <Typography paragraph sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px',cursor: 'pointer' }} onClick={handleBackToTasks}>
          <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 'inherit', cursor: 'pointer' }} /> Back to Tasks
        </Typography>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
             Vendor Allocation 
        </Typography>
        </div>
        <IconButton
          onClick={handleOpenVideoModal}
          style={{
            height: '40px',
            borderRadius: '8px',
            padding: '8px, 6px, 8px, 16px',
            marginTop: "10px",
            backgroundColor: '#3D85C6',
            textTransform: 'none',
            width: '150px',
          }}
        >
          <Typography sx={{ fontSize: '14px', lineHeight: '24px', textTransform: 'none', fontWeight: '600', color: 'white' }}>How to Allocate</Typography>
        </IconButton>
        <VideoModal
          open={openVideoModal}
          onClose={handleCloseVideoModal}
          onGotItClick={handleGotItClick}
        />
      </Box>
    </div>
  );
};

export default VendorStepHeading;
