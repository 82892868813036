import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AnalyticsPayload, DashoardAnalyticsExpertState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/analyticsExpertDashboard';

const initialState: DashoardAnalyticsExpertState = {
  dashboardAnalyticsExpert: {
    count: undefined,
    results: []
  },
  error: null,
  getStatus: STATUS.NOT_STARTED,
  downloadDashboardAnalyticsByExpertStatus: STATUS.NOT_STARTED,
  analyticsDashboardExpertDownload: ''
};

export const getDashboardExpertAnalytics = createAsyncThunk(
  '/company/analytics/by_experts/get',
  async (payload: AnalyticsPayload = { association_type: '' }, thunkAPI) => {
    const { search, page, pageSize, sort_by, sort_order, project_id, type, association_type, download } = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort_by && { sort_by }),
      ...(sort_order && { sort_order }),
      ...(project_id && { project_id }),
      ...(type && { type }),
      ...(association_type && { association_type }),
      ...(download != undefined && { download: download.toString() }),
    }).toString();
    console.log(type)
    const response = await api.get(`/company/analytics/by_experts?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getDashboardExpertAnalyticsDownload = createAsyncThunk(
  '/company/analytics/by_experts/get/download',
  async (payload: AnalyticsPayload = { association_type: '' }, thunkAPI) => {
    const { search, page, pageSize, sort_by, sort_order, project_id, type, association_type, download } = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort_by && { sort_by }),
      ...(sort_order && { sort_order }),
      ...(project_id && { project_id }),
      ...(type && { type }),
      ...(association_type && { association_type }),
      ...(download != undefined && { download: download.toString() }),
    }).toString();
    console.log(type)
    const response = await api.get(`/company/analytics/by_experts?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const dashboardanalyticsExpertsSlice = createSlice({
  name: 'dashboardanalyticsExpert',
  initialState,
  reducers: {
    setDownloadDashoardAnalyticsByExpertStatus : (state, actions) => {
      state.downloadDashboardAnalyticsByExpertStatus = actions.payload
    }
  },
  extraReducers
});

export const { setDownloadDashoardAnalyticsByExpertStatus } = dashboardanalyticsExpertsSlice.actions
export default dashboardanalyticsExpertsSlice.reducer;
