import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, ToggleButton, ToggleButtonGroup, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useStyles } from '../../assets/styles/listTableStyle';
import Error from '../error/error';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TableRowItem from '../analytics/expertAnalyticsTableRow';
import Pagination from './ExpertPagination';
import DownloadIcon from '@mui/icons-material/Download';
import { getDashboardExpertAnalytics, getDashboardExpertAnalyticsDownload, setDownloadDashoardAnalyticsByExpertStatus } from '../../redux/slices/analytics/dashboardExpert';
import { SearchBar } from '../inputField/textfield';
import debounce from 'lodash.debounce';
import SnackbarNotification from '../snackbar/snackbarNotification';

const ExpertWiseOverview = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useSelector((state: RootState) => state.dashboardExpert.dashboardAnalyticsExpert)
  const getStatus = useSelector((state: RootState) => state.dashboardExpert.getStatus);
  const { downloadDashboardAnalyticsByExpertStatus, analyticsDashboardExpertDownload } = useSelector((state: RootState) => state.dashboardExpert)
  const [currentPageTasker, setCurrentPageTasker] = useState(1)
  const [currentPageReviewer, setCurrentPageReviewer] = useState(1);
  const [itemsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [tableSwitch, setTableSwitch] = useState("TASK");
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadForTask = () => {
    dispatch(getDashboardExpertAnalyticsDownload({
      type: 'TASK',
      download: true
    }))
    handleMenuClose();
  };

  const handleDownloadForReview = () => {
    dispatch(getDashboardExpertAnalyticsDownload({
      type: 'REVIEW',
      download: true
    }))
    handleMenuClose();
  };

  useEffect(() => {
    if (downloadDashboardAnalyticsByExpertStatus === STATUS.SUCESS) {
      if (typeof analyticsDashboardExpertDownload == "string") {
        window.open(analyticsDashboardExpertDownload, '_blank')
      }
    }

    if (downloadDashboardAnalyticsByExpertStatus == STATUS.FAILED) {
      setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
    return () => {
      if (downloadDashboardAnalyticsByExpertStatus === STATUS.SUCESS || downloadDashboardAnalyticsByExpertStatus === STATUS.FAILED) {
        dispatch(setDownloadDashoardAnalyticsByExpertStatus(STATUS.NOT_STARTED))
      }

    }
  }, [downloadDashboardAnalyticsByExpertStatus])

  const handleSort = (column: string) => {
    const formattedHeader = column.toLowerCase().replace(/\s+/g, '_');
    const newOrder = sortColumn === formattedHeader && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(formattedHeader);
    setSortOrder(newOrder);
  };


  useEffect(() => {
    if (tableSwitch === "TASK" || tableSwitch === "REVIEW") {
      setSearchTerm('');
    }

    dispatch(getDashboardExpertAnalytics({
      page: tableSwitch === "TASK" ? currentPageTasker : currentPageReviewer,
      pageSize: itemsPerPage,
      sort_by: sortColumn,
      sort_order: sortOrder,
      type: tableSwitch,
      search: searchTerm
    }))
  }, [dispatch, currentPageTasker, currentPageReviewer, itemsPerPage, sortColumn, sortOrder, tableSwitch, searchTerm])


  const totalPages = Math.ceil((analytics.count || 0) / itemsPerPage);

  const handlePageTasker = (pageNumber: number) => {
    setCurrentPageTasker(pageNumber);

  };
  const handlePageReviewer = (pageNumber: number) => {
    setCurrentPageReviewer(pageNumber);
  }

  const headers = ["NAME", "EMAIL", "PSEUDO NAME", "TOTAL ALLOCATED", "ACTIVE TASKS", "TOTAL COMPLETED", "TOTAL QUALITY ACCEPTED", "TOTAL DELIVERED", "NOT STARTED TASKS", "TOTAL REJECTED", "TOTAL AVAILABLE TO REVIEW", "AVERAGE TIME TO COMPLETE", "LAST TASK TIME", "TASK IN LAST 24 HOURS", "TASK IN LAST 48 HOURS", "TASK IN LAST 72 HOURS"];

  const handleChangeToggle = (event: any, table: string) => {
    if (table !== null) {
      setTableSwitch(table);
      setSearchTerm('')
      dispatch(getDashboardExpertAnalytics({ type: event.target.value }));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }} >

        <ToggleButtonGroup
          color="standard"
          value={tableSwitch}
          exclusive
          onChange={handleChangeToggle}
        >
          <ToggleButton
            value="TASK"
            style={{
              border: 'none',
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
              color: tableSwitch === 'TASK' ? '#03BBD2' : '#6C6C6C',
              borderBottom: tableSwitch === 'TASK' ? '3px solid #03BBD2' : 'none',
              backgroundColor: 'inherit',
              textTransform: 'none'
            }}
          >
            Tasker
          </ToggleButton>
          <ToggleButton
            value="REVIEW"
            style={{
              border: 'none',
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
              color: tableSwitch === 'REVIEW' ? '#03BBD2' : '#6C6C6C',
              borderBottom: tableSwitch === 'REVIEW' ? '3px solid #03BBD2' : 'none',
              backgroundColor: 'inherit',
              textTransform: 'none'
            }}
          >
            Reviewer
          </ToggleButton>
        </ToggleButtonGroup>

        <IconButton
          style={{
            height: '40px',
            borderRadius: '10px',
            padding: '8px 6px 8px 6px',
            marginTop: '10px',
            color: 'white',
            marginRight: '20px',
            backgroundColor: downloadDashboardAnalyticsByExpertStatus === STATUS.PENDING ? '#dfdfdf' : '#3D85C6',
          }}
          //onClick={handleExpertAnalyticsDownload}
          onClick={handleMenuClick}
          disabled={downloadDashboardAnalyticsByExpertStatus == STATUS.PENDING}
          size='small'
        >
          {downloadDashboardAnalyticsByExpertStatus == STATUS.PENDING ? <CircularProgress size={25} /> : <DownloadIcon />}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDownloadForTask}>Download for Task</MenuItem>
          <MenuItem onClick={handleDownloadForReview}>Download for Review</MenuItem>
        </Menu>
      </div>

      <SearchBar onSearchChange={debouncedResults} />
      <TableContainer>
        {getStatus === STATUS.PENDING && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        )}
        {getStatus === STATUS.SUCESS && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
              <TableRow>
                {headers.map((header) => (
                  <TableCell align="left" onClick={() => handleSort(header)} sx={{ fontSize: '11px', fontWeight: 700, lineHeight: '14px' }} key={header}>
                    {header}  <SwapVertIcon fontSize='small' sx={{ marginLeft: '1px', cursor: 'pointer' }} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>

              {getStatus === STATUS.SUCESS && analytics.results && analytics.results.length > 0 ? (
                <>
                  {analytics.results.map((row: any) => (
                    <>
                      <TableRowItem
                        key={row.id}
                        row={row}
                      />
                    </>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className={classes.noUsersMessage}>
                    <Error message={'There is no data added as of yet.'} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Pagination
        currentPage={tableSwitch === "TASK" ? currentPageTasker : currentPageReviewer}
        totalPages={totalPages}
        paginate={tableSwitch === "TASK" ? handlePageTasker : handlePageReviewer}
        itemsPerPage={itemsPerPage}
      />
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default ExpertWiseOverview;
