import React, { useEffect, useState } from 'react'
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Error from '../error/error';
import { STATUS } from '../../utils/status';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useStyles } from '../../assets/styles/listTableStyle';
import { useParams } from 'react-router-dom';
import VendorListTableRow from './VendorListTableRow';
import { getVendorWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard';
import Pagination from './Pagination';
import { ActiveProjectPaginationEntries } from '../../routes/dashboard/dashboardMain';

const ActiveProjectVendorList = () => {

  const headers = [
    "PSEUDO NAME",
    "TASKERS ONBOARDED",
    "TOTAL TASK",
    "TASK ALLOCATED",
    "TASKS COMPLETED",
    "% TASK COMPLETED",
    "TASKS NOT STARTED",
    "ACTIVE TASKS",
    "QUALITY ACCEPTED",
    "QUALITY REJECTED",
    "TASKS DELIVERED",
    "TASKS NO REVIEW",
    "TIME TAKEN TO COMPLETE",
    "AVG TASKS/DAY"
  ];

  const analytics = useSelector((state: RootState) => state.dashboard.getVendorWiseAnalytics)
  const getStatus = useSelector((state: RootState) => state.dashboard.getVendorWiseAnalyticsActionStatus);
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const totalPages = Math.ceil((analytics?.count || 0) / itemsPerPage);

  useEffect(() => {
    dispatch(getVendorWiseAnalyticsAction({ page: currentPage, pageSize: itemsPerPage as number, association_type: "VENDOR" }));
  }, [dispatch, id, currentPage, itemsPerPage])

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPageActiveProject = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    dispatch(getVendorWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "VENDOR" }));
  };

  const handleOnChangeActiveVendor = (value: number) => {
    setItemsPerPage(value)
  }

  return (
    <>
      <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', justifyContent: 'space-between' }} >
        <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>List of active vendors</Typography>
        <ActiveProjectPaginationEntries
          itemsPerPage={itemsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPageActiveProject}
          handleOnChange={handleOnChangeActiveVendor}
        />
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  align="left"
                  sx={{ fontSize: '10px', fontWeight: 600, lineHeight: '14px' }}
                  key={header}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>

          </TableHead>
          <TableBody>
            {getStatus === STATUS.PENDING ?
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={8}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
              : getStatus === STATUS.SUCESS && analytics && analytics.results.length > 0 ? (
                <>
                  {analytics.results.map((row: any) => {
                    return (
                      <VendorListTableRow key={row.id} row={row} />
                    )
                  })}

                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className={classes.noUsersMessage}>
                    <Error message={'There is no data added as of yet.'} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>

      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
      />
    </>
  )
}

export default ActiveProjectVendorList