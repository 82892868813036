import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useNavigate } from 'react-router-dom';

const ProjectHeading = () => {
  const navigate = useNavigate()
  const handleBackToGoods = () => {
    navigate('/projects');
  };
  return (
    <>
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        color: '#13282A',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        height:'84px',
      }}>
        <Typography paragraph sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px',cursor: 'pointer' }} onClick={handleBackToGoods}>
          <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 'inherit', cursor: 'pointer' }} /> Back to Projects
        </Typography>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
          Add Project
        </Typography>
      </Box>
    </div>
    </>
  );
}


export default ProjectHeading;
