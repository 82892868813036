import React, { useState, useEffect } from 'react';
import { Typography, Slider, Box, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { AddButton } from '../button/button';
import { useParams } from 'react-router-dom';
import { updateCompletedTask } from '../../redux/slices/upload';
import { setRateSelectEdited } from '../../redux/reducers/isEditTaskCompleted';
import { STATUS } from '../../utils/status';

interface RatingConfig {
    question: string;
    range_start: string;
    range_end: string;
}

interface RatingValue {
    id: string;
    rating_param: string;
    rating: number;
}

interface ActionValue {
    config_details: {
        value: RatingConfig[];
    };
    ratings: RatingValue[];
}

interface RatingActionProps {
    actionValue: ActionValue[];
    actionIndex: number;
    action: string;
    result: any;
    projectStatus: string;
    heading?: string;
    taskStatus: string;
    metaQCStatus: string;
}

export default function RatingAction({
    action,
    actionValue,
    actionIndex,
    result,
    projectStatus,
    taskStatus,
    metaQCStatus
}: RatingActionProps) {
    const [ratings, setRatings] = useState(actionValue);
    const [selectedValues, setSelectedValues] = useState<RatingValue[]>([]);
    const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
    const [isProjectStatusCompleted, setIsProjectStatusCompleted] = useState(projectStatus && projectStatus === 'COMPLETED')
    const [isTaskDelivered, setIsTaskDelivered] = useState(taskStatus && taskStatus === 'DELIVERED')
    const [isMetaQCDone, setIsMetaQCDone] = useState(metaQCStatus && (metaQCStatus === 'READY FOR DELIVERY' || metaQCStatus === 'DELIVERED'))
    const [isEdited, setIsEdited] = useState(false);
    console.log({isEdited, fetchPermissions})
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const [currentConfig, setcurrentConfig] = useState<any>(undefined)
    const { updateCompletedTaskStatus } = useSelector((state: RootState) => state.upload);

    useEffect(() => {
        setIsProjectStatusCompleted(projectStatus && projectStatus === 'COMPLETED')
    }, [projectStatus])

    useEffect(() => {
        setIsTaskDelivered(taskStatus && taskStatus === 'DELIVERED')
    }, [taskStatus])

    useEffect(() => {
        setIsMetaQCDone(metaQCStatus && (metaQCStatus === 'READY FOR DELIVERY' || metaQCStatus === 'DELIVERED'))
    }, [metaQCStatus])

    useEffect(() => {
        const initialSelectedValues = actionValue.flatMap((value) => value.ratings);
        setSelectedValues(initialSelectedValues);
    }, [actionValue]);

    const handleRatingChange = (valueIndex: number, configIndex: number, newRating: number) => {
        if (isProjectStatusCompleted || isTaskDelivered || isMetaQCDone) return;
        setRatings((prevRatings) =>
            prevRatings.map((item, idx) =>
                idx === valueIndex
                    ? {
                        ...item,
                        ratings: item.ratings.map((rating) =>
                            rating?.rating_param === item.config_details.value[configIndex].question
                                ? { ...rating, rating: newRating }
                                : rating
                        ),
                    }
                    : item
            )
        );

        setSelectedValues((prevSelectedValues) => {
            const updatedValues = prevSelectedValues.map((rating) =>
                rating?.rating_param === ratings[valueIndex].config_details.value[configIndex].question
                    ? { ...rating, rating: newRating }
                    : rating
            );
            return updatedValues;
        });

        setIsEdited(true);
        dispatch(setRateSelectEdited(true));
    };

    if (!actionValue || actionValue.length === 0) {
        return <Typography>No rating available.</Typography>;
    }

    const handleSave = () => {
        if (isProjectStatusCompleted || isTaskDelivered || isMetaQCDone) return;

        const payload = {
            project_id: id,
            task_id: result.tasks[0].task_id,
            allocation_id: result.tasks[0].task_allocation_id,
            response_id: result.tasks[0].responses[0].id,
            action_id: actionIndex,
            action: action,
            action_value: {
                id: selectedValues[0].id,
                ratings: selectedValues,
            },
        };
        setcurrentConfig(actionIndex)
        dispatch(updateCompletedTask(payload));
        setIsEdited(false);
        dispatch(setRateSelectEdited(false));
    };

    return (
        <>
            {ratings.map((value, valueIndex) => (
                <div key={valueIndex} style={{ marginBottom: '30px' }}>
                    {value?.config_details?.value?.map((config, configIndex) => {
                        const selectedRating =
                            actionValue.flatMap((value) => value.ratings).find((rating) => rating?.rating_param === config.question)?.rating || parseInt(config.range_start);
                        return (
                            <Box key={configIndex} sx={{ marginBottom: '30px', display: 'flex' }}>
                                <Typography sx={{ fontWeight: 'bold', marginBottom: '15px', marginRight: '10%' }}>
                                    {config.question}:
                                </Typography>
                                <Slider
                                    defaultValue={selectedRating}
                                    min={parseInt(config.range_start)}
                                    max={parseInt(config.range_end)}
                                    step={1}
                                    marks={[
                                        { value: parseInt(config.range_start), label: config.range_start },
                                        { value: parseInt(config.range_end), label: config.range_end },
                                    ]}
                                    valueLabelDisplay="on"
                                    sx={{
                                        width: '50%',
                                        color: 'green',
                                        '& .MuiSlider-thumb': {
                                            color: 'green',
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: 'green',
                                                transform: 'translateY(35px) rotate(0deg)',
                                                '&:before': {
                                                    transform: 'rotate(180deg)',
                                                },
                                            },
                                        },
                                        '& .MuiSlider-track': {
                                            color: 'green',
                                        },
                                        '& .MuiSlider-markLabel': {
                                            fontWeight: 'bold',
                                        },
                                    }}
                                    {...((fetchPermissions?.completing_task_action_details?.[2] === "edit" && !isTaskDelivered && !isMetaQCDone) && {
                                        onChange: (e, newValue) =>
                                            handleRatingChange(valueIndex, configIndex, newValue as number)
                                    })}
                                    disabled={!!(isTaskDelivered || isMetaQCDone)}
                                />
                            </Box>
                        );
                    })}
                </div>
            ))}
            {(!isProjectStatusCompleted || (!isTaskDelivered || !isMetaQCDone)) && isEdited && (
                fetchPermissions.completing_task_action_details?.[2] === "edit" ? (
                    <AddButton
                        startIcon={
                            actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING ? (
                                <CircularProgress />
                            ) : null
                        }
                        disabled={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING}
                        onClick={handleSave}
                    >
                        Save
                    </AddButton>
                )
                    : <></>)}
        </>
    );
}
