import React, { useState, useEffect } from 'react';
import {
    Typography,
    Checkbox,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    ListItemText,
} from '@mui/material';
import { AddButton } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useParams } from 'react-router-dom';
import { updateCompletedTask } from '../../redux/slices/upload';
import { setMultiSelectEdited } from '../../redux/reducers/isEditTaskCompleted';

interface MultiSelectActionProps {
    actionValue: any[];
    actionIndex: number;
    action: string;
    result: any;
    projectStatus: string;
    heading?: string;
    taskStatus: string;
    metaQCStatus: string;
}

export default function MultiSelectAction({
    action,
    actionValue,
    actionIndex,
    result,
    projectStatus,
    heading,
    taskStatus,
    metaQCStatus
}: MultiSelectActionProps) {
    const [isProjectStatusCompleted, setIsProjectStatusCompleted] = useState(projectStatus && projectStatus === 'COMPLETED')
    const [isTaskDelivered, setIsTaskDelivered] = useState(taskStatus && taskStatus === 'DELIVERED')
    const [isMetaQCDone, setIsMetaQCDone] = useState(metaQCStatus && (metaQCStatus === 'READY FOR DELIVERY' || metaQCStatus === 'DELIVERED'))
    const [selectedOptions, setSelectedOptions] = useState(actionValue);
    const [selectedValues, setSelectedValues] = useState<string[]>(actionValue[0]?.selected.map((item: any) => item.value) || []);
    const [isEdited, setIsEdited] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { updateCompletedTaskStatus } = useSelector((state: RootState) => state.upload);
    const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);

    useEffect(() => {
        setIsProjectStatusCompleted(projectStatus && projectStatus === 'COMPLETED')
    }, [projectStatus])

    useEffect(() => {
        setIsTaskDelivered(taskStatus && taskStatus === 'DELIVERED')
    }, [taskStatus])

    useEffect(() => {
        setIsMetaQCDone(metaQCStatus && (metaQCStatus === 'READY FOR DELIVERY' || metaQCStatus === 'DELIVERED'))
    }, [metaQCStatus])

    useEffect(() => {
        setSelectedValues(actionValue[0]?.selected.map((item: any) => item.value) || []);
    }, [actionValue]);

    const handleOptionChange = (event: any) => {
            if (isProjectStatusCompleted || isTaskDelivered || isMetaQCDone) return;
            const newSelectedValues = event.target.value;
            const newSelected = selectedOptions[0]?.config_details?.value?.value
                ?.filter((opt: any) => newSelectedValues.includes(opt.value))
                .map((opt: any) => ({ index: opt.index, value: opt.value }));
    
            setSelectedValues(newSelectedValues);
            setSelectedOptions(prevOptions => {
                return prevOptions.map(option => {
                    if (option.id === selectedOptions[0]?.id) {
                        return {
                            ...option,
                            selected: newSelected,
                        };
                    }
                    return option;
                });
            });
    
            setIsEdited(true);
            dispatch(setMultiSelectEdited(true));
        };

    const handleSave = () => {
        if (isProjectStatusCompleted || isTaskDelivered || isMetaQCDone) return;

        const payload = {
            project_id: id,
            task_id: result.tasks[0].task_id,
            allocation_id: result.tasks[0].task_allocation_id,
            response_id: result.tasks[0].responses[0].id,
            action_id: actionIndex,
            action: action,
            action_value: {
                id: selectedOptions[0].id,
                selected: selectedValues.map(value => ({ value })),
            },
        };
        dispatch(updateCompletedTask(payload));
        setIsEdited(false);
        dispatch(setMultiSelectEdited(false));
    };
    return (
        <>
            <FormControl fullWidth>
                            <Typography sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                {heading} :
                            </Typography>
                            <Select
                                multiple
                                value={selectedValues}
                                renderValue={(selected) => selected.join(', ')}
                                disabled={!!(isTaskDelivered || isMetaQCDone)}
                                onChange={
                                    fetchPermissions?.completing_task_action_details?.[2] === "edit" &&
                                        !isTaskDelivered &&
                                        !isMetaQCDone
                                        ? handleOptionChange
                                        : undefined
                                }
                            >
                                {selectedOptions?.flatMap(option =>
                                    option.config_details?.value?.value?.map((opt: any) => (
                                        <MenuItem key={opt.index} value={opt.value}>
                                            <Checkbox
                                                checked={selectedValues.includes(opt.value)}
                                            />
                                            <ListItemText primary={opt.value} />
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>

            {(!isProjectStatusCompleted || (!isTaskDelivered || !isMetaQCDone)) && isEdited && (
                fetchPermissions.completing_task_action_details?.[2] === "edit" ? (
                    <AddButton
                        startIcon={actionIndex === selectedOptions[0]?.id && updateCompletedTaskStatus === STATUS.PENDING ? (
                            <CircularProgress />
                        ) : null}
                        disabled={actionIndex === selectedOptions[0]?.id && updateCompletedTaskStatus === STATUS.PENDING}
                        onClick={handleSave}
                    >
                        Save
                    </AddButton>
                )
                    : <></>)}
        </>
    );
}
