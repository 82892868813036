import React, { useEffect, useState } from 'react'
import TaskCompletedList from './taskCompletedList'
import AnalyticsListTable from './expertAnalyticsList'
import { Paper, ToggleButton, SelectChangeEvent, ToggleButtonGroup, Typography, CircularProgress, Button, IconButton, Menu, MenuItem } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store/appStore'
import { useParams } from 'react-router-dom'
import { getAnalytics } from '../../redux/slices/analytics'
import { getExpertAnalytics, getExpertAnalyticsDownload, setDownloadAnalyticsByExpertStatus } from '../../redux/slices/analytics/experts'
import VendorAnalyticsListTable from './vendorAnalyticsList'
import DownloadIcon from '@mui/icons-material/Download';
import { STATUS } from '../../utils/status'
import SnackbarNotification from '../snackbar/snackbarNotification'
// import { SearchBar } from '../inputField/textfield'
// import { getUpload } from '../../redux/slices/upload'
// import PaginationEntries from '../pagination/paginationEntries'



function toTitleCase(str: string) {
  return str.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const AnalyticsMain = () => {
  const analytics = useSelector((state: RootState) => state.analytics.analytics.data)
  const getAnalyticsStatus = useSelector((state:RootState) => state.analytics.getStatus)
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [tableSwitch, setTableSwitch] = useState("TASK")
  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const { downloadAnalyticsByExpertStatus, analyticsExpertDownload } = useSelector((state: RootState) => state.analyticsExpert)
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget); 
  };

  const handleMenuClose = () => {
    setAnchorEl(null);  
  };

  const handleDownloadForTask = () => {
    dispatch(getExpertAnalyticsDownload({
      project_id: id,
      type: 'TASK',
      association_type: association_type,
      download: true
    }))
    handleMenuClose();
  };

  const handleDownloadForReview = () => {
    dispatch(getExpertAnalyticsDownload({
      project_id: id,
      type: 'REVIEW',
      association_type: association_type,
      download: true
    }))
    handleMenuClose();
  };

  const handleChangeToggle = (event: any, table: string,) => {
    if (table !== null) {
      setTableSwitch(table);
      // navigate(`/project/${id}?tab=${newAlignment}`);
      dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: association_type }))
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  useEffect(() => {
    dispatch(getAnalytics({ project_id: id, association_type: association_type }))
  }, [dispatch, id])

  useEffect(() => {
    if (downloadAnalyticsByExpertStatus === STATUS.SUCESS) {
      if (typeof analyticsExpertDownload == "string") {
        window.open(analyticsExpertDownload, '_blank')
      }
    }

    if (downloadAnalyticsByExpertStatus == STATUS.FAILED) {
      setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }

    return () => {
      if (downloadAnalyticsByExpertStatus == STATUS.SUCESS || downloadAnalyticsByExpertStatus == STATUS.FAILED) {
        dispatch(setDownloadAnalyticsByExpertStatus(STATUS.NOT_STARTED))
      }

    }
  }, [downloadAnalyticsByExpertStatus])

  let backgrounds = [
    'linear-gradient(45deg, #42a5f5, #478ed1)',
    'linear-gradient(45deg, #ffa726, #fb8c00)',
    'linear-gradient(45deg, #66bb6a, #4caf50)'
  ]

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4%' }}>
        {Object.keys(analytics).map((key:string, index) => {
          return (
            <Paper
              elevation={3}
              key={key}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: backgrounds[(index+1)%backgrounds.length],
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              { getAnalyticsStatus == STATUS.PENDING ? <CircularProgress size={17} /> : <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>
                  {String(analytics[key as keyof typeof analytics])}
                </p>
                <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>{toTitleCase(key.replaceAll('_', ' '))}</h3>
              </div>}
            </Paper>
          )
        })}
      </div>

      <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>Tasks by Date</Typography>
      <TaskCompletedList />
      {association_type === 'COMPANY' &&
        (
          <>
            <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>Vendor Analytics</Typography>
            <VendorAnalyticsListTable />
          </>
        )
      }

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
        <Typography
          sx={{
            marginTop: '40px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700,
            fontSize: '20px', lineHeight: '14px', marginBottom: '20px'
          }}
        >
          Tasks by Experts
        </Typography>
        <IconButton
          style={{
            height: '40px',
            borderRadius: '10px',
            padding: '8px 6px 8px 6px',
            marginTop: '10px',
            color: 'white',
            marginRight: '20px',
            backgroundColor: downloadAnalyticsByExpertStatus === STATUS.PENDING ? '#dfdfdf' : '#3D85C6',
          }}
          //onClick={handleExpertAnalyticsDownload}
          onClick={handleMenuClick}
          disabled={downloadAnalyticsByExpertStatus == STATUS.PENDING}
          size='small'
        >
          {downloadAnalyticsByExpertStatus == STATUS.PENDING ? <CircularProgress size={25} /> : <DownloadIcon />}
        </IconButton>
        <Menu
        anchorEl={anchorEl}  
        open={Boolean(anchorEl)}  
        onClose={handleMenuClose}  
      >
        <MenuItem onClick={handleDownloadForTask}>Download for Task</MenuItem>
        <MenuItem onClick={handleDownloadForReview}>Download for Review</MenuItem>
      </Menu>
      </div>

      {/* <div style={{display: "flex", gap: "45px"}}>
        <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
        <div style={{marginTop: "-10px"}}>
          <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
      </div> */}

      <ToggleButtonGroup
        color="standard"
        value={tableSwitch}
        exclusive
        onChange={handleChangeToggle}
      >
        <ToggleButton
          value="TASK"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'TASK' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'TASK' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          Tasker
        </ToggleButton>
        <ToggleButton
          value="REVIEW"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'REVIEW' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'REVIEW' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          Reviewer
        </ToggleButton>
      </ToggleButtonGroup>
      <AnalyticsListTable tableSwitch={tableSwitch} />
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </div>
  )
}

export default AnalyticsMain
