import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, MenuItem, Select, TextareaAutosize, TextField, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { createConfig, setCreateConfigStatus, setUpdateConfigStatus, updateConfig, updateConfigData } from '../../redux/slices/config';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { STATUS } from '../../utils/status';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { AddButton } from '../button/button';
import TaskReviewToggle from './taskReviewToggle';
import { useStyles } from '../../assets/styles/configStyle';

interface ListTypeContentProps {
  chipLabel: string;
  chipId: string;
  listFields: string[];
  taskCategory: string;
}

const ListTypeContent: React.FC<ListTypeContentProps> = ({
  chipLabel,
  chipId,
  listFields,
  taskCategory,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const createStatus = useSelector((state: RootState) => state.config.createStatus);
  const updateStatus = useSelector((state: RootState) => state.config.updateStatus);
  const listTypeData = useSelector((state: RootState) => state.config.currentConfig);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [selectedOption, setSelectedOption] = useState<'TASK' | 'REVIEW'>('TASK');
  const [formValues, setFormValues] = useState({ heading: '' });
  const [formValuesIdentifier, setFormValuesIdentifier] = useState({ identifier: '' });
  const [localListFields, setLocalListFields] = useState<string[]>(listFields.length > 0 ? listFields : ['']);
  const getConfig = useSelector((state: RootState) => state.config.currentConfig);
  const config_id = getConfig.id;
  const [order, setOrder] = useState('');
  const [localIsChecked, setLocalIsChecked] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>([''])
  const [tag, setTag] = useState("")

  const handleOrderChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setOrder(e.target.value);
    }
  };



  useEffect(() => {
    if (getConfig) {
      const { value, type } = getConfig;
      if(chipLabel == 'adjectives_list' && value && value.value && Array.isArray(value.value)) {
        setSelectedValue(value.value.map((val: { tag: any; }) => val.tag))
      }
      if (Array.isArray(value)) {
        setLocalListFields(value);
        setFormValues({ heading: '' });
      } else if (value && value.value && Array.isArray(value.value)) {
        if (value.value.length > 0 && typeof value.value[0] === 'object' && 'value' in value.value[0]) {
          const extractedValues = value.value.map((item: any) => item.value);
          setLocalListFields(extractedValues);
          if (value.label) {
            setFormValues({ heading: value.label });
          } else {
            setFormValues({ heading: '' });
          }
        } else {
          setLocalListFields(value.value);
          if (value.label) {
            setFormValues({ heading: value.label });
          } else {
            setFormValues({ heading: '' });
          }
        }
      } else {
        setLocalListFields(['']);
        setFormValues({ heading: '' });
      }

      if (getConfig.identifier !== undefined) {
        setFormValuesIdentifier({ identifier: getConfig.identifier });
      }
      setSelectedOption(type);
      setOrder(getConfig.order);
      setLocalIsChecked(getConfig.required);
      
    //  setSelectedValue(tags);

    }
  }, [getConfig]);


  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      ...(config_id && { id: config_id }),
      project_id: id,
      task_category_id: taskCategory,
      config_id: chipId,
      name: chipLabel,
      value: {
        label: formValues.heading,
        value: localListFields.filter(el => el.length > 0).map((field, index) => ({
          ...(index && { index: index + 1 }),
          ...(field && { value: field }),
          ...(selectedValue[index] && {tag: selectedValue[index]}) ,
        })),
      },
      type: selectedOption,
      identifier: formValuesIdentifier.identifier,
      ...(order && Number(order) !== 0 && { order: Number(order) }),
      required: localIsChecked
    }

    const newUpdateValues = Object.keys(payload).reduce((acc, key) => {
      const fetchedValue = listTypeData[key];
      const newValue = payload[key];
      if (
        key in listTypeData &&
        newValue !== fetchedValue &&
        (fetchedValue !== '' || newValue !== undefined) &&
        newValue !== ''
      ) {
        acc[key] = newValue;
      }

      return acc;
    }, {} as Record<string, string | boolean | string[] | undefined>);

    if (config_id) {
      dispatch(updateConfig({ id: config_id, ...newUpdateValues }));
    } else {
      dispatch(createConfig(payload));
    }
  };

  const handleAddListField = () => {
    if (!Array.isArray(localListFields)) {
      return;
    }
    const newFields = [...localListFields, ''];
    setLocalListFields(newFields);
  };

  const handleRemoveField = (index: number) => {
    if (index === 0) {
      return;
    }

    const updatedFields = [...localListFields];
    updatedFields.splice(index, 1);

    setLocalListFields(updatedFields);
  };

  const handleFieldChange = (index: number, value: string) => {
    const updatedFields = [...localListFields];
    updatedFields[index] = value;
    setLocalListFields(updatedFields);
  };

  const handleTagChange = (index: number, value: string) => {
    const updatedFields = [...selectedValue];
    updatedFields[index] = value;
    setSelectedValue(updatedFields);
    console.log({ value });
  };

  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        handleSnackbar('Config Added Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }
      if (createStatus === STATUS.FAILED) {
        handleSnackbar('error occured please try again', 'warning');
      }
    }
    handleCreateStatus();
    return () => {
      dispatch(setCreateConfigStatus(STATUS.NOT_STARTED));
    };
  }, [createStatus]);

  useEffect(() => {
    console.log({ updateStatus })
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Config Updated Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }

      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();
    return () => {
      dispatch(setUpdateConfigStatus(STATUS.NOT_STARTED));
    };
  }, [updateStatus, navigate, dispatch]);

  const handleOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: 'TASK' | 'REVIEW'
  ) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const handleTextFieldChange = (index: number, value: string) => {
    handleFieldChange(index, value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setLocalIsChecked(checked);
  };
  const handleChangeViolative = (index: number, value: string) => {
    handleTagChange(index, value);
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedFields = Array.from(localListFields);
    const [removed] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, removed);

    setLocalListFields(reorderedFields);
    dispatch(updateConfigData({ listFields: reorderedFields }));
  };

  const renderField = (field: string, index: number) => (
    <Draggable key={index} draggableId={index.toString()} index={index}>
      {(provided: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.fieldContainer}
          style={provided.draggableProps.style}
        >
          <div className={classes.fieldContent}>
            <DragIndicatorIcon fontSize="medium" className={classes.dragIcon} />
            {chipLabel === 'radio' && (
              <RadioButtonUncheckedIcon fontSize="medium" className={classes.dragIcon} />
            )}
            {chipLabel === 'checkbox' && (
              <CheckBoxOutlineBlankIcon fontSize="medium" className={classes.dragIcon} />
            )}
            <TextField
              size="small"
              placeholder={`Option ${index + 1}`}
              margin="normal"
              value={field}
              onChange={(e) => handleTextFieldChange(index, e.target.value)}
              className={classes.textField}
            />
            {index !== 0 && (
              <div className={classes.removeButton} onClick={() => handleRemoveField(index)}>
                <ClearIcon fontSize="small" className={classes.removeIcon} />
              </div>
            )}
            {chipLabel === 'adjectives_list' &&
              <FormControl>
                <Select variant="standard" sx={{ m: 1, minWidth: 100, marginBottom: "15px" }}
                  labelId="demo-simple-select-label"
                  id={`demo-simple-select-${index}`}
                  label="Tag"
                  value={selectedValue[index] || ( selectedValue[index]) || ""}
                  onChange={(e) => handleChangeViolative(index, e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>Select tag</MenuItem>
                  <MenuItem value={"violative"}>Violative</MenuItem>
                  <MenuItem value={"non-violative"}>Non-Violative</MenuItem>
                </Select>
              </FormControl>
            }
          </div>
        </div>
      )}
    </Draggable>
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 600, lineHeight: '24px', color: '#10383D', marginTop: 2, marginBottom: 2, textTransform: 'capitalize', }}>
          {chipLabel.replace(/_/g, ' ')}
        </Typography>
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Enter Name:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Name will identify unique configs </Typography>
        </Typography>
        <TextField
          placeholder="Enter Name"
          name="formValuesIdentifier"
          value={formValuesIdentifier.identifier}
          onChange={(e) => setFormValuesIdentifier({ identifier: e.target.value })}
          sx={{ marginLeft: '70px', marginTop: '10px' }}
          size="small"
        />
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>Enter Heading:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This heading will be visible on expert side </Typography>
        </Typography>
        <TextareaAutosize
          placeholder="Enter heading"
          name="formValues"
          value={formValues.heading}
          onChange={(e) => setFormValues({ heading: e.target.value })}
          style={{ marginLeft: '50px', marginTop: '10px', height: "50px", padding: "5px", width: "227px", border: "1px solid" }}

        />
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Order:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Order will arrange config on expert side </Typography>
        </Typography>
        <TextField
          placeholder="Enter Order"
          name="order"
          value={order}
          onChange={handleOrderChange}
          sx={{ marginLeft: '35px', marginTop: '10px' }}
          size="small"
        />
      </div>

      <div style={{ display: 'flex' }}>
        <Typography
          sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px', marginRight: '50px' }}
        >
          Choose Type:

        </Typography>
        <TaskReviewToggle
          selectedOption={selectedOption}
          onChange={handleOptionChange}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px' }}>
          Do you want to mark it required?
        </Typography>
        <Checkbox
          checked={localIsChecked}
          onChange={handleCheckboxChange}
          color="primary"
          sx={{ marginTop: '5px' }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '35px', marginRight: '22px' }}
        >
          Enter Options:
        </Typography>
        <div style={{ display: "flex" }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="listFields">
              {(provided: any) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                  {Array.isArray(localListFields) && localListFields.map((field, index) => renderField(field, index))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        </div>
      </div>
      <AddButton onClick={handleAddListField}>Add More</AddButton>
      <AddButton className={classes.addButton} onClick={handleSubmit}>{config_id ? "Update" : "Submit"}</AddButton>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default ListTypeContent;
