import React from 'react';
import { TableRow, Tooltip, Typography } from '@mui/material';
import { TableCellBody } from '../table/tableCell';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface TableRowItemProps {
  row: any;
  onEditClick: (row: any) => void;
  onDeleteClick: (userId: string) => void;
}

const TableRowItem: React.FC<TableRowItemProps> = ({ row, onEditClick, onDeleteClick }) => {
  
  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return `${str.substring(0, maxLength)}...`;
    } else {
      return str;
    }
  };

  const renderValue = () => {
    if (Array.isArray(row.value) && row.value.length > 0) {
      if (typeof row.value[0] === 'string' || typeof row.value[0] === 'number') {
        return row.value.map((value: any, index: number) => (
          <Typography
            key={index}
            variant="body1"
            component="div"
            style={{
              fontFamily: 'Montserrat',
              color: '#10383D',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '24px',
              textAlign: 'left',
              paddingTop:'2px'
            }}
          >
            {value}
          </Typography>
        ));
      }
      return row.value.map((obj: any, index: number) => {
        const keys = Object.keys(obj);
        const values = Object.values(obj);
  
        const formattedContent = keys.map((key, i) => {
          if (
            Array.isArray(values[i]) &&
            (values[i] as any[]).length > 0 &&
            typeof (values[i] as any[])[0] === 'object'
          ) {
            const nestedContent = (values[i] as any[]).map((nestedObj: any) => {
              return Object.entries(nestedObj)
                .map(([nestedKey, nestedValue]) => `${nestedKey}: ${nestedValue}`)
                .join(', ');
            }).join(' ');
            const nestedContentString = JSON.stringify(nestedContent);
            const truncatedValueString = truncateString(nestedContentString, 20);
            return (
              <div key={key} style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: '1', fontWeight: 'bold' }}>{key} :</div>
                <Tooltip title={nestedContentString} arrow>
                  <Typography
                    variant="body1"
                    component="div"
                    style={{
                      flex: '1',
                      marginLeft: '1px',
                      overflowWrap: 'anywhere',
                    }}
                  >
                    {truncatedValueString}
                  </Typography>
                </Tooltip>
              </div>
            );
          } else {
            const truncatedValue = typeof values[i] === 'string' ? truncateString(values[i] as string, 20) : String(values[i]);
            return (
              <div key={key} style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: '1', fontWeight: 'bold' }}>{key} :</div>
                <Tooltip title={String(values[i])} arrow>
                  <Typography
                    variant="body1"
                    component="div"
                    style={{
                      flex: '1',
                      marginLeft: '1px',
                      overflowWrap: 'anywhere',
                      fontFamily: 'Montserrat',
                      color: '#10383D',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '24px',
                      textAlign: 'left',
                    }}
                  >
                    {truncatedValue}
                  </Typography>
                </Tooltip>
              </div>
            );
          }
        });
  
        return (
          <div key={index} style={{ marginBottom: '20px' }}>
            {formattedContent}
          </div>
        );
      });
    } else if (typeof row.value === 'object' && row.value !== null) {
      if ('label' in row.value && Array.isArray(row.value.value)) {
        const { value } = row.value;
        return (
          <div>
            <ul>
              {value.map((item: any) => (
                <li key={item.index}>
                  <div style={{ flex: '2', marginLeft: '10px' }}>{item.value}</div>
                </li>
              ))}
            </ul>
          </div>
        );
      } else if ('title' in row.value) {
        const { title } = row.value;
        return (
          <div>
            <Typography
              variant="body1"
              component="div"
              style={{
                fontFamily: 'Montserrat',
                color: '#10383D',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
          </div>
        );
      } else {
        const { max_turns, min_turns } = row.value;
        return (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: '1', fontWeight: 'bold' }}>Max Turns:</div>
              <div style={{ flex: '2', marginLeft: '10px' }}>{max_turns}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: '1', fontWeight: 'bold' }}>Min Turns:</div>
              <div style={{ flex: '2', marginLeft: '10px' }}>{min_turns}</div>
            </div>
          </div>
        );
      }
    } else if (row.config_details && row.config_details.type === 'boolean') {
      return 'true';
    } else if (typeof row.value === 'string' || typeof row.value === 'number') {
      return truncateString(String(row.value), 20);
    } else {
      return '-';
    }
  };

  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          '&:last-child td, &:last-child th': { borderBottom: 0 },
        }}
        style={{'verticalAlign':'baseline'}}
      >
        <TableCellBody>{row.order? row.order : 'Order does not exist'}</TableCellBody>
        <TableCellBody>{row.type}</TableCellBody>
        <TableCellBody>{row.name && row.name.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}</TableCellBody>
        <TableCellBody>{row.required ? <CheckIcon sx={{color:'green'}}/>: <ClearIcon sx={{color: 'red'}}/>}</TableCellBody>
        <TableCellBody>{renderValue()}</TableCellBody>
        <TableCellBody>
          <EditNoteOutlinedIcon sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => onEditClick(row)} />
          <DeleteOutlineOutlinedIcon
            sx={{ color: '#C00F00', cursor: 'pointer', marginRight: '5px' }}
            onClick={() => onDeleteClick(row.id)}
          />
        </TableCellBody>
      </TableRow>
    </>
  );
};

export default TableRowItem;
