import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useNavigate, useParams } from 'react-router-dom';

const DeliveredTaskUploadHeading = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const handleBackToProjects = () => {
    navigate(`/project/${id}?tab=task`);
  };

  return (
    <>
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        color: '#13282A',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        height:'84px',
      }}>
        <Typography paragraph sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px', cursor: 'pointer' }} onClick={handleBackToProjects}>
            <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 'inherit', cursor: 'pointer' }} />Back to Tasks
        </Typography>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
          Upload Delivered Task
        </Typography>
      </Box>
    </div>
    </>
  );
}


export default DeliveredTaskUploadHeading;
