import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import ResponseAction from './responseAction';

interface TaskResponsesProps {
    task: {
        responses: Array<any>;
        task_allocation_status: string;
        meta_qc_status: string;
    };
    taskIndex: number;
    results: any;
}

export default function TaskResponses({ task, taskIndex, results }: TaskResponsesProps) {
    if (!task.responses || task.responses.length === 0) {
        return (
            <Typography variant="body1" color="textSecondary">
                No responses available.
            </Typography>
        );
    }

    // const actions = task.responses.flatMap(response => response.actions || []);

    if (task.responses.flatMap(response => response.actions || []).length === 0) {
        return (
            <Typography variant="body1" color="textSecondary">
                No actions available.
            </Typography>
        );
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
            {task?.responses?.flatMap(response => response.actions || [])
                .map(ac => (ac.action_value?.map((av: any) => {
                    return (
                        {id:ac.id, action: ac.action, order: av?.config_details?.order, action_value: [av] }
                    )
                })))
                .flatMap(a_arr => a_arr)
                .sort((a, b) => a.order - b.order)
                .map((action: any, actionIndex: number) => {
                        return (
                            <div key={actionIndex} style={{ flex: '1 1 calc(100% - 16px)' }}>
                                <ResponseAction metaQCStatus={task.meta_qc_status} taskStatus={task.task_allocation_status} action={action} actionIndex={actionIndex} result={results}/>
                            </div>
                        )
                })
            }
        </div>
    );
}
