import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dashboardReducer from '../slices/dashboard/dashboard'
import userReducer from '../slices/userSlice/addUserSlice'
import loginReducer from "../slices/login/singInSlice";
import expertReducer from '../slices/expertSlice/addExpertSlice'
import projectReducer from '../slices/projectSlice/addProjectSlice'
import configReducer from '../slices/config/index'
import uploadReducer from '../slices/upload/index'
import companyReducer from '../slices/company/index'
import taskIdReducer from '../reducers/taskIdSlice'
import allocationReducer from '../slices/allocation/index'
import selectedEmailsReducer from '../reducers/selectedEmail'
import deadlineReducer from '../reducers/deadline'
import allocationTaskReducer from '../reducers/allocationReducer'
import analyticsReducer from '../slices/analytics/index';
import analyticsExpertReducer from '../slices/analytics/experts';
import analyticsCompletedReducer from '../slices/analytics/completed';
// import taskCompletedReducer from '../slices/taskCompleted/index';
import selectedEmailsReviewReducer from '../reducers/selectedEmailReviewer';
import taskDataReducer from "../slices/taskData/index";
import enteredEmailsReducer from "../reducers/emailAllocationSlice";
import fileAllocationReducer from "../reducers/sheetAllocationType3"
import uploadSheetType2Reducer from '../reducers/uploadSheetType2';
import bulkAllocationReducer from '../slices/bulkAllocation/index';
import projectExpertReducer from '../slices/projectExpert';
import emailTasksReducer from '../reducers/emailTaskSlice';
import vendorListReducer from '../slices/vendorList/index'
import vendorReducer from '../slices/vendor/index';
import deliveredReducer from '../slices/deliveredFile/index';
import copyPasteRowErrors from '../reducers/copyPasteRowErrors';
import isEditedUnsavedReducer from '../reducers/isEditTaskCompleted';
import analtyicsVendorProject from '../slices/analytics/vendor';
import metaqcreducer from '../slices/taskCompleted/metaQC';
import dashboardExpertReducer from '../slices/analytics/dashboardExpert'

const rootReducer = combineReducers({
  users: userReducer,
  dashboard: dashboardReducer,
  user: loginReducer,
  experts: expertReducer,
  projects: projectReducer,
  config: configReducer,
  upload: uploadReducer,
  company: companyReducer,
  tasks: taskIdReducer,
  allocation: allocationReducer,
  selectedEmails: selectedEmailsReducer,
  selectedEmailsReview: selectedEmailsReviewReducer,
  deadline: deadlineReducer,
  allocationTask: allocationTaskReducer,
  analytics: analyticsReducer,
  analyticsExpert: analyticsExpertReducer,
  analyticsCompleted: analyticsCompletedReducer,
  analyticsVendor: analtyicsVendorProject,
  // taskCompleted: taskCompletedReducer,
  taskData: taskDataReducer,
  enteredEmails: enteredEmailsReducer,
  fileAllocation: fileAllocationReducer,
  uploadSheet : uploadSheetType2Reducer,
  bulkAllocation: bulkAllocationReducer,
  projectExpert: projectExpertReducer,
  emailTasks: emailTasksReducer,
  vendorList: vendorListReducer,
  vendors: vendorReducer,
  delivered: deliveredReducer,
  rowErrors : copyPasteRowErrors,
  configReducer: configReducer,
  isEditedUnsaved: isEditedUnsavedReducer,
  configData: configReducer,
  metaqc: metaqcreducer,
  dashboardExpert: dashboardExpertReducer
});

export const store = configureStore({
  reducer:  rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
