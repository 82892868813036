import React, { useEffect, useState } from "react";
import { TableRow } from "@mui/material";
import { TableCellBody } from "../table/tableCell";
import { onBoardDateParts } from "../../utils/helper";

interface TableRowItemProps {
  row: any;
}

const TableRowItem: React.FC<TableRowItemProps> = ({row} ) => {
  return (
    <TableRow
      key={row.id}
      sx={{
        '&:last-child td, &:last-child th': { borderBottom: 0 },
      }}
    >
      
      <TableCellBody>{row.name ? row.name : '--'}</TableCellBody>
      <TableCellBody>{row.email ? row.email : 'Email does not exist'}</TableCellBody>
      <TableCellBody>{row.pseudo_name ? row.pseudo_name : "-"}</TableCellBody>
      <TableCellBody>{row.total_allocated? row.total_allocated : '0'}</TableCellBody>
      <TableCellBody>{row.active_tasks ? row.active_tasks : '0'}</TableCellBody>
      <TableCellBody>{row.total_completed ? row.total_completed : '0'}</TableCellBody>    
      <TableCellBody>{row.total_quality_accepted ? row.total_quality_accepted : '0'}</TableCellBody>
      <TableCellBody>{row.total_delivered ? row.total_delivered : '0'}</TableCellBody>
      <TableCellBody>{row.not_started_tasks ? row.not_started_tasks : '0'}</TableCellBody>
      <TableCellBody>{row.total_rejected? row.total_rejected : '0'}</TableCellBody>
      <TableCellBody>{row.total_available_to_review? row.total_available_to_review : '0'}</TableCellBody>
      {/* <TableCellBody>{row.total_awaiting_to_review? row.total_awaiting_to_review : '0'}</TableCellBody>*/}
      {/* <TableCellBody>{row.total_submitted? row.total_submitted : '0'}</TableCellBody> */}
      <TableCellBody>{row.average_time_to_complete ? row.average_time_to_complete : '0'}</TableCellBody>
      <TableCellBody>{row.last_task_time? row.last_task_time : '0'}</TableCellBody>
      <TableCellBody>{row.task_in_last_24_hours? row.task_in_last_24_hours : '0'}</TableCellBody>
      <TableCellBody>{row.task_in_last_48_hours? row.task_in_last_48_hours : '0'}</TableCellBody>
      <TableCellBody>{row.task_in_last_72_hours? row.task_in_last_72_hours : '0'}</TableCellBody>
      
    </TableRow>
  );
};

export default TableRowItem;
