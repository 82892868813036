import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { DashoardAnalyticsExpertState } from '../interfaces/interface';
import { getDashboardExpertAnalytics, getDashboardExpertAnalyticsDownload } from '../slices/analytics/dashboardExpert';

const ExtraReducers = (builder: ActionReducerMapBuilder<DashoardAnalyticsExpertState>) => {
  builder
  .addCase(getDashboardExpertAnalytics.pending, (state) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getDashboardExpertAnalytics.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.dashboardAnalyticsExpert = action.payload;
  })
  .addCase(getDashboardExpertAnalytics.rejected, (state, action) => {
    state.getStatus = STATUS.FAILED;
  })
  .addCase(getDashboardExpertAnalyticsDownload.pending, (state) => {
    state.downloadDashboardAnalyticsByExpertStatus = STATUS.PENDING;
  })
  .addCase(getDashboardExpertAnalyticsDownload.fulfilled, (state, action) => {
    state.downloadDashboardAnalyticsByExpertStatus = STATUS.SUCESS;
    state.analyticsDashboardExpertDownload = action.payload.data;
  })
  .addCase(getDashboardExpertAnalyticsDownload.rejected, (state, action) => {
    state.downloadDashboardAnalyticsByExpertStatus = STATUS.FAILED;
  });
};

export default ExtraReducers;
