import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Checkbox, Popover, ListItem, List, ListItemText, Drawer, SelectChangeEvent, Typography, Badge, IconButton, Tooltip, Box } from '@mui/material';
import { TableCellHead } from '../../table/tableCell';
import { STATUS } from '../../../utils/status';
import Type1AllocationTableRow from './type1AllocationTableRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { useStyles } from '../../../assets/styles/listTableStyle';
import Error from '../../error/error';
import { getUpload } from '../../../redux/slices/upload';
import { useParams } from 'react-router-dom';
import TaskCompleted from '../../taskCompleted/taskCompleted';
import Pagination from '../../uploadTask/taskPagination';
import PaginationEntries from '../../pagination/paginationEntries';
import { clearIds, setIds } from '../../../redux/reducers/taskIdSlice';
import { SearchBar } from '../../inputField/textfield';
import debounce from 'lodash.debounce';
import Filter from '../../Filters/Filter';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getVendorAction } from '../../../redux/slices/vendor';

interface Type1AllocationProps {
  allocationType: string | null;
}
interface Filters {
  taskName: string;
  taskAllocation: string;
  reviewAllocation: string;
  pseudoname: string;
  selectedVendor: string;
  selectedTaskType: any;
  selectedStatus: any;

}

const Type1Allocation: React.FC<Type1AllocationProps> = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const { id } = useParams();
  const { option } = useParams();
  const words = option && option.split(' ');
  const taskerType = words && words[0];
  const allocationtype = words && words[1].toLowerCase()
  const [type] = useState(
    taskerType && taskerType === 'Task'
      ? allocationtype === 'unallocation'
        ? 'get_completed_tasks, get_not_started_tasks, task_allocation'
        : allocationtype === 'allocation'
        ? 'get_unallocated_tasks'
        : 'get_allocated_tasks'
      : taskerType === 'Review'
      ? allocationtype === 'unallocation'
        ? 'get_completed_reviews, get_not_started_reviews, review_allocation'
        : allocationtype === 'allocation'
        ? 'get_unallocated_reviews'
        : 'get_allocated_reviews'
      : ''
  );
  

  // const [type] = useState(taskerType && taskerType === 'Task' 
  //   ? (allocationtype === 'unallocation' ? 'get_completed_tasks, get_not_started_tasks, task_allocation' : 'get_unallocated_tasks')
  //   : (taskerType === 'Review' ? (allocationtype === 'unallocation' ? 'get_completed_reviews, get_not_started_reviews, review_allocation' : 'get_unallocated_reviews') : ''));

  const getStatus = useSelector((state: RootState) => state.upload.getStatus);
  const upload = useSelector((state: RootState) => state.upload.upload);
  const totalPages = Math.ceil((upload.count || 0) / itemsPerPage);
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const currentPageTasks = upload.results || [];
  const currentSelectedIds = selectedIds.filter(selectedId =>
    currentPageTasks.some(task => task.id === selectedId.id)
  );


  console.log(taskerType)
  const [filterCountApplied, setFilterCountApplied]: any = useState(0)
  const [anchorE3, setAnchorE3] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [searchType, setSearchType] = useState("")
  const [value, setValue] = useState('1')
  const [openList, setOpenList] = useState<string | null>(null);

  const [drawer, setDrawer] = useState(false);
  const [currentTaskID, setcurrentTaskID] = useState<string>('')
  const [openFilterList, setOpenFilterList] = useState(false)
  const [taskName, setTaskName] = useState("")
  const [taskAllocation, setTaskAllocation] = useState("")
  const [reviewAllocation, setReviewAllocation] = useState("")
  const [pseudoname, setPseudoName] = useState("")
  const [handleClicked, setHandleClicked] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [filterCount, setFilterCount]: any = useState(0)

  const [filterInFocus, setfilterInFocus] = useState('')
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const [appliedFilterValues, setAppliedFilterValues] = useState<string[]>([]);
  const [searchCheckBox, setSearchCheckBox] = useState("")
  const [showCompletedTask, setShowCompletedTask] = useState(false)
  const [showUnallocatedTask, setShowUnallocatedTask] = useState(false)
  const [byTaskListOpen, setByTaskListOpen] = useState(false)
  const [byReviewListOpen, setByReviewListOpen] = useState(false)
  const [selectedTaskItems, setSelectedTaskItems] = useState<string[]>([]);
  const [taskSearchType, setTaskSearchType] = useState("")
  const [vendorId, setVendorId] = useState("")
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any>(null)
  const [selectedTaskType, setSelectedTaskType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [searchTermAutocomplete, setSearchTermAutocomplete] = useState("")
  const [bySelectVendorOpen, setBySelectVendorOpen] = useState(false)
  const [taskNameListOpen, setTaskNameListOpen] = useState(false)
  const [taskEmailNameListOpen, setTaskEmailNameListOpen] = useState(false)
  const [reviewerListOpen, setReviewerListOpen] = useState(false)
  const [pseudoListOpen, setPseudoListOpen] = useState(false)


  const [pagesSearchByInput, setPagesSearchByInput] = useState(0)
  const [openTaskUnallocationModel, setOpenTaskUnallocationModel]: any = useState(false)
  const [openReviewUnallocationModel, setOpenReviewUnallocationModel]: any = useState(false)
  const updateCompletedTaskStatus = useSelector((state: RootState) => state.upload.updateCompletedTaskStatus)
  const { radio, content, checkbox } = useSelector((state: RootState) => state.isEditedUnsaved);
  const [selectedSearchTerm, setSelectedSearchTerm] = useState("");
  const [filterTypes, setFilterType] = useState("")



  const ByReviewListItems = ["Active", "Completed", "Not Started", "Quality Reject", "Awaiting Review", "Quality Accepted", "Delivered", "Not Allocated"]
  const headers = [
    "TASK NAME",
    `${taskerType === "Review" ? "REVIEW" : "TASK"} STATUS`,
    `${taskerType === "Review" ? "REVIEWER" : "TASKER"} PSEUDO NAME`,
    `${taskerType === "Review" ? "REVIEW" : "TASKER"} ALLOCATION`
  ];
  // const allSelected = (upload.results || []).length > 0 && (upload.results || []).every(row => selectedIds.some(id => id.id === row.id));
  const allSelected = currentPageTasks.length > 0 && currentPageTasks.every(row => selectedIds.some(id => id.id === row.id));
  const Vendors = useSelector((state: RootState) => state.vendors.vendors)
  const getVendorStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)

  useEffect(() => {
    dispatch(getUpload({ search: searchTerm, project_id: id || '', page: currentPage, type: type, pageSize: itemsPerPage }));
  }, [dispatch, searchTerm, id, currentPage, itemsPerPage, type]);

  useEffect(() => {
    dispatch(getVendorAction({
      search_query: searchTerm,
    }));
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (getVendorStatus === STATUS.SUCESS) {
      setOptions(Vendors.results)
    }
  }, [getVendorStatus])

  const handleSelectAllClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const allIds = (upload.results || []).map(row => ({ id: row.id, task_name: row.task_name }));

  const handleDoneClick = () => {
    const isAllSelectedOnPage = currentPageTasks.every(task =>
      selectedIds.some(selectedId => selectedId.id === task.id)
    );

    if (isAllSelectedOnPage) {
      const newSelectedIds = selectedIds.filter(
        (selectedId) => !currentPageTasks.some(task => task.id === selectedId.id)
      );
      dispatch(setIds(newSelectedIds));
    } else {
      const newSelectedIds = [
        ...selectedIds,
        ...currentPageTasks.filter(task =>
          !selectedIds.some(selectedId => selectedId.id === task.id)
        ).map(task => ({ id: task.id, task_name: task.task_name }))
      ];
      dispatch(setIds(newSelectedIds));
    }

    handleClosePopover();
  };


  const handleChangeRowsPerPage = (value: number) => {
    setItemsPerPage(value as number);
    setCurrentPage(1);
    dispatch(getUpload({ search: searchTerm, page: 1, pageSize: value as number, project_id: id || "", type: type }));
  };
  // const handleOnChange = (value:number) => {
  //   setItemsPerPage(value)
  // }

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1)
    setSearchTerm(event.target.value);

  };

  const handleClick = (event: any) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose = () => {
    console.log("PRESSED")
    setAnchorE3(null);
    setSearchType("")
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const handleTaskNameToggle = (listName: string) => {
    setTaskNameListOpen(!taskNameListOpen);
    setfilterInFocus("task_name");
    setOpenList((prev) => (prev === listName ? null : listName))
  };
  const resetTaskName = () => {
    setTaskName("")
  };

  const handleFieldChange = (action: string, e: any) => {
    switch (action) {
      case "taskName":
        setTaskName(e.target.value);
        break;
      case "alloc":
        setTaskAllocation(e.target.value);
        break;
      case "reviewAllocation":
        setReviewAllocation(e.target.value);
        break;
      case "pseudoName":
        setPseudoName(e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const filters: Filters = {
      taskName,
      taskAllocation,
      reviewAllocation,
      pseudoname,
      selectedVendor,
      selectedTaskType,
      selectedStatus,
    };
    const activeFilters: (keyof Filters)[] = Object.keys(filters).filter((key) => filters[key as keyof Filters] !== "") as (keyof Filters)[];
    const activeFilterValues = Object.values(filters).filter((value) => value !== "");

    setFilterCount(activeFilters.length);
    setAppliedFilterValues(activeFilterValues);
  }, [taskName, taskAllocation, reviewAllocation, pseudoname, selectedTaskType, selectedStatus, selectedVendor]);
  const handleTaskEmailNameToggle = (listName: string) => {
    setTaskEmailNameListOpen(!taskEmailNameListOpen);
    setfilterInFocus("task_allocation");
    setOpenList((prev) => (prev === listName ? null : listName))
  };
  const resetTaskEmailName = () => {
    setTaskAllocation("");
    setSearchTerm("")
  };
  const handleByTaskListClick = (listName: string) => {
    setByTaskListOpen(!byTaskListOpen)
    setfilterInFocus("by_task_list_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const resetTaskType = () => {
    setSelectedTaskType(null);
    setByTaskListOpen(false)
  }
  const handleTaskTypeSelect = (type: any) => {
    setSelectedTaskType(type);
    setByTaskListOpen(false)
  };
  const handleByReviewListClick = (listName: string) => {
    setByReviewListOpen(!byReviewListOpen)
    setfilterInFocus("by_review_list_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const handleBySelectVendorClick = (listName: string) => {
    setBySelectVendorOpen(!bySelectVendorOpen)
    setfilterInFocus("by_select_vendor_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const resetStatus = () => {
    setSelectedStatus([])
    setByReviewListOpen(false)
  }
  const resetSelectVendor = () => {
    setSearchTermAutocomplete("")
    setSelectedVendor(null)
    setBySelectVendorOpen(false)
    setVendorId("")
  }
  const handleStatusToggle = (status: any) => {
    setSelectedStatus((prev: any) => {
      if (prev.includes(status)) {
        return prev.filter((item: any) => item !== status);
      } else {
        return [...prev, status];
      }
    });
  }

  const handleSearchChangeAutoComplete = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermAutocomplete(event.target.value)
  }
  const debouncedResultsForAutoComplete = useMemo(() => { return debounce(handleSearchChangeAutoComplete, 300); }, []);
  useEffect(() => {
    return () => {
      debouncedResultsForAutoComplete.cancel();
    };
  });
  const handleAutocompleteInput = (e: any) => {
    debouncedResultsForAutoComplete(e)
  };

  const handleAutoCompleteOpen = () => {
    setOpenAutoComplete(true);
  };

  const handleAutoCompleteClose = () => {
    setOpenAutoComplete(false);
  };

  const handleApplyClick = () => {
    let local_type = type
    let local_search_term = searchTerm
    let local_filter_count = 0

    setFilterCountApplied(filterCount)

    if (taskAllocation.length > 0) {
      local_type = "allocation"
      local_search_term = taskAllocation
      local_filter_count = local_filter_count + 1
    }

    if (reviewAllocation.length > 0) {
      local_type = "allocation"
      local_search_term = reviewAllocation
      local_filter_count = local_filter_count + 1

    }

    if (taskName.length > 0) {
      local_type = "task_name"
      local_search_term = taskName
      local_filter_count = local_filter_count + 1

    }

    if (pseudoname.length > 0) {
      local_type = "pseudo_name"
      local_search_term = pseudoname
      local_filter_count = local_filter_count + 1

    }

    if (vendorId) {
      // local_type = local_search_term ? local_type + ',' + 'GET_VENDOR_STATUS' : 'GET_VENDOR_STATUS';
      local_type = local_type + ',' + 'GET_VENDOR_STATUS';
      local_filter_count = local_filter_count + 1
    }
    if (selectedStatus.length > 0) {
      // if(!local_type.includes('GET_VENDOR_STATUS')) {
      // local_type = local_search_term ? local_type + ',' + 'GET_BY_STATUS' : 'GET_BY_STATUS';
      // }
      local_type = local_type + ',' + "GET_BY_STATUS";
      local_filter_count = local_filter_count + 1
    }
    if (selectedTaskType != null) {
      local_filter_count = local_filter_count + 1

    }

    dispatch(getUpload({
      search: local_search_term,
      project_id: id || '',
      page: currentPage,
      type: local_type === type ? local_type : `${local_type},${type}`,
      // type: local_type,
      pageSize: itemsPerPage,
      allocated_task_type: selectedTaskType,
      statuses: selectedStatus,
      vendor_id: vendorId,
    }))
    setAnchorE3(null)
    setFilterCountApplied(local_filter_count)
    setFilterType(local_type)
  }


  useEffect(() => {
    dispatch(getVendorAction({
      is_vendorblock: false,
      search_query: searchTermAutocomplete
    }))
  }, [dispatch, searchTermAutocomplete])



  return (
    <>
      <div style={{ display: 'flex', height: '6.5vh', justifyContent: 'space-between', marginBottom: '5px' }} >
        <div style={{ display: 'flex' }}>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: '600', marginTop: '10px' }}>{allocationtype === 'allocation' ? 'Unallocated Tasks' : 'Allocated Tasks'}</Typography>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: '400', marginTop: '10px', marginLeft: '10px', color: 'grey' }}>
            ({getStatus === STATUS.PENDING ? <CircularProgress size={"20px"} /> : `${selectedIds.length > 0 && getStatus === STATUS.SUCESS ? `${selectedIds.length}/${upload.count}` : `${upload.count}`}`})
          </Typography>
          {/* {allocationtype === 'unallocation' &&
            <div style={{ marginTop: '18px', marginLeft: '20px' }}>
              <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </div>
          } */}
        </div>
        <div style={{ flex: 3 }} className={classes.actionsContainer}>
          <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <Badge badgeContent={filterCountApplied} color="error">
              <IconButton
                color="primary"
                aria-label="filter options"
                onClick={handleClick}
                value={selectedValue}
                autoFocus={false}
                sx={{

                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  background: "linear-gradient(45deg, #42a5f5, #478ed1)",
                  borderRadius: "30%",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    background: "linear-gradient(45deg, #478ed1, #42a5f5)",
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Tooltip title={
                  // appliedFilterValues.length > 0 ? (
                  //   <List style={{ padding: "5px" }}>
                  //     {appliedFilterValues.map((filter, index) => (
                  //       <ListItem key={index} style={{ padding: "0px" }}>
                  //         <Typography variant="body1">{filter}</Typography>
                  //       </ListItem>
                  //     ))}
                  //   </List>
                  // ) : (
                  //   "No filters applied"
                  // )
                  "Filters"
                }
                  placement="bottom-end"
                  arrow>
                  <FilterAltIcon fontSize="medium" sx={{ color: "white" }} />
                </Tooltip>
              </IconButton>
            </Badge>
          </Box>
        </div>
      </div>

      <div style={{ overflowY: 'scroll', height: '52.5vh' }} >
        <TableContainer>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ overflow: 'hidden', backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }} >
                <TableRow>
                  <TableCellHead>
                    <Checkbox
                      checked={allSelected}
                      onClick={handleSelectAllClick}
                      size='small'
                    />
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <div>
                        <List>
                          <ListItem
                            selected={allIds.length === selectedIds.length}
                            onClick={() => handleDoneClick()}
                            sx={{ cursor: 'pointer' }}
                          >
                            <ListItemText
                              primary={`${currentSelectedIds.length === currentPageTasks.length ? 'Deselect' : 'Select'} ${currentPageTasks.length} tasks`}
                            />
                          </ListItem>
                        </List>
                      </div>
                    </Popover>
                  </TableCellHead>
                  {headers.map((header) => (
                    <TableCellHead key={header}>{header}</TableCellHead>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getStatus === STATUS.SUCESS && upload && upload.results && upload.results.length > 0 ? (
                  upload.results.map((row: any) => (
                    <>
                      <Type1AllocationTableRow
                        key={row.id}
                        row={row}
                      />
                    </>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length} className={classes.noUsersMessage}>
                      <Error message={'There are no tasks added as of yet. You can add a task to see it here.'} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>

      <Filter
        anchorE3={anchorE3}
        setAnchorE3={setAnchorE3}
        onClose={handleClose}
        setSearchType={setSearchType}
        value={value}
        handleChange={handleChange}
        handleTaskNameToggle={handleTaskNameToggle}
        openList={openList}
        resetTaskName={resetTaskName}
        filterInFocus={filterInFocus}
        taskName={taskName}
        handleFieldChange={handleFieldChange}
        setfilterInFocus={setfilterInFocus}
        handleTaskEmailNameToggle={handleTaskEmailNameToggle}

        resetTaskEmailName={resetTaskEmailName}
        handleByTaskListClick={handleByTaskListClick}
        taskAllocation={taskAllocation}
        resetTaskType={resetTaskType}
        selectedTaskType={selectedTaskType}
        handleTaskTypeSelect={handleTaskTypeSelect}
        handleByReviewListClick={handleByReviewListClick}
        byReviewListOpen={byReviewListOpen}
        resetStatus={resetStatus}
        ByReviewListItems={ByReviewListItems}
        handleStatusToggle={handleStatusToggle}
        selectedStatus={selectedStatus}
        handleBySelectVendorClick={handleBySelectVendorClick}

        resetSelectVendor={resetSelectVendor}
        openAutoComplete={openAutoComplete}
        handleAutoCompleteOpen={handleAutoCompleteOpen}
        handleAutoCompleteClose={handleAutoCompleteClose}
        selectedVendor={selectedVendor}
        options={options}
        setVendorId={setVendorId}
        setSelectedVendor={setSelectedVendor}
        loading={loading}
        handleAutocompleteInput={handleAutocompleteInput}
        handleApplyClick={handleApplyClick}
      />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
      />
    </>
  )
}

export default Type1Allocation
