import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Linechart from '../../routes/dashboard/Linechart'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store/appStore'
import { useParams } from 'react-router-dom'
import { getTaskWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard'

const ReviewingOverview = () => {

    const { getTaskWiseAnalytics, getTaskWiseAnalyticsStatus } = useSelector((state: RootState) => state.dashboard)

    const { id } = useParams()
    const dispatch = useDispatch<any>()
    //   const [tableSwitch, setTableSwitch] = useState("TASK")
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [step, setStep] = useState(0);


    // const handleChangeToggle = (event: any, table: string,) => {
    //     if (table !== null) {
    //         setTableSwitch(table);
    //         dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: "Admin" }))
    //     }
    // };

    //   useEffect(() => {
    //     // dispatch(getAnalytics({ project_id: "", type: "all_project" }))
    //     dispatch(getMetricsAction({ association_type: "COMPANY" }))
    //     dispatch(getPseudoNameWiseAnalyticsAction())
    //     dispatch(getProjectWiseAnalyticsAction({ association_type: "COMPANY" }))
    //     dispatch(getTaskWiseAnalyticsAction({ allocation_type: "REVIEW" }));
    //     dispatch(getVendorWiseAnalyticsAction({ page: currentPage, pageSize: itemsPerPage as number, association_type: "VENDOR" }))

    //   }, [dispatch])

    useEffect(() => {
        dispatch(getTaskWiseAnalyticsAction({ allocation_type: "REVIEW" }));
    },[dispatch])


    // const handleChangeRowsPerPageActiveProject = (value: number) => {
    //     setItemsPerPage(value);
    //     setCurrentPage(1);
    //     dispatch(getProjectWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "COMPANY" }));
    // };
    // const handleChangeRowsPerPageActiveVendors = (value: number) => {
    //     setItemsPerPage(value);
    //     setCurrentPage(1)
    //     dispatch(getVendorWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "VENDOR" }))
    // }

    const handleOnChangeActiveProject = (value: number) => {
        setItemsPerPage(value)
    }
    const handleOnChangeActiveVendors = (value: number) => {
        setItemsPerPage(value)
    }

    return (
        <>
            <Typography sx={{ marginTop: '40px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '30px' }}>Daily reviews completion activity by pseudo name</Typography>
            <Box>
                <Linechart data={getTaskWiseAnalytics.data} dataStatus={getTaskWiseAnalyticsStatus} title={"No. of reviews"} />
            </Box>
        </>
    )
}

export default ReviewingOverview