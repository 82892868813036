import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { CircularProgress, TableHead, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useStyles } from '../../assets/styles/listTableStyle';
import TableRowItem from './dataTableInformationRowItem';
import Error from '../error/error';
import { TableCellHead } from '../table/tableCell';
import { createBulkAnalyse } from '../../redux/slices/bulkAllocation';
import { useParams } from 'react-router-dom';
import { SelectedId } from '../../redux/reducers/taskIdSlice';

interface DataInformationAllocationTableProps {
  allocationType: string;
  taskerType: string;
  selectedType: string;
  selectedBox: string;
}

const DataInformationAllocationTable: React.FC<DataInformationAllocationTableProps> = ({ allocationType, taskerType, selectedType, selectedBox }) => {
  const classes = useStyles();
  const [tableSwitch, setTableSwitch] = useState("CURRENT");
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const fileType3 = useSelector((state: RootState) => state.fileAllocation.file)
  const selectedEmails = useSelector((state: RootState) => state.selectedEmails.selectedEmail);
  const selectedEmailsReview = useSelector((state: RootState) => state.selectedEmailsReview.selectedEmailReview);
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const emailTasksType2 = useSelector((state: RootState) => state.emailTasks.emailTasks);
  const analyseData = useSelector((state: RootState) => state.bulkAllocation.bulkAllocation)
  const createAnalyseStatus = useSelector((state: RootState) => state.bulkAllocation.createStatus);
  const fileDataType2 = useSelector((state: RootState) => state.uploadSheet.fileUpload);
  const headers = ["EMAIL", "ACTIVE TASK COUNT", "COMPLETED TASK COUNT", "PUSHBACKED TASKS", "NOT STARTED TASKS", "IN REVIEW TASKS", "REVIEWED TASKS", "TOTAL ALLOCATED TASKS", "CURRENT TASK COUNT", `TOTAL ALLOCATED AFTER THIS ${allocationType.toUpperCase()}`]

  const handleChangeToggle = (event: any, table: string) => {
    if (table !== null) {
      setTableSwitch(table);

      if (selectedType === 'Type 1') {
        const payload = {
          project_id: id,
          data: {
            tasks: selectedIds.map((selectedId: SelectedId) => selectedId.id),
            experts: taskerType === 'TASK' ? selectedEmails : selectedEmailsReview,
          },
          action_type: allocationType,
          type: taskerType,
          analyse_all: table === 'ALL',
          action: allocationType === 'allocation' ? 'task_expert_mapping' : 'task_expert_unmapping',
        }
        dispatch(createBulkAnalyse({ payload }))
      }

      if (selectedBox === 'copyPaste' && selectedType === 'Type 2') {
        const payload = {
          project_id: id,
          type: taskerType,
          action: allocationType === 'allocation' ? 'task_distribution' : 'bulk_task',
          action_type: allocationType,
          analyse_all: table === 'ALL',
          data: emailTasksType2.map(task => ({
            email: task.email,
            count: task.count,
            ...(task.vendorId ? { vendor_id: task.vendorId } : {})
          }))

        }
        dispatch(createBulkAnalyse({ payload }))
      }
      else if (selectedBox === 'uploadSheet' && selectedType === 'Type 2') {
        const formData = new FormData()
        formData.append("project_id", id || '')
        formData.append("action", allocationType === 'allocation' ? 'task_distribution' : 'bulk_task')
        formData.append("type", taskerType || 'TASK')
        formData.append("action_type", allocationType || '')
        if (table === 'ALL') {
          formData.append("analyse_all", 'true');
        };
        if (fileDataType2) {
          formData.append("data", fileDataType2);
        }
        dispatch(createBulkAnalyse({ payload: formData }))

      }
      if (selectedType === 'Type 3') {
        const formData = new FormData()
        formData.append("project_id", id || '')
        formData.append("action", allocationType === 'allocation' ? 'task_assignment' : 'task_unassignment')
        formData.append("type", taskerType || 'TASK')
        formData.append("action_type", allocationType || '')
        if (table === 'ALL') {
          formData.append("analyse_all", 'true');
        }
        if (fileType3) {
          formData.append("data", fileType3);
        }
        dispatch(createBulkAnalyse({ payload: formData }))
      }
    }


  };

  return (
    <>
      <ToggleButtonGroup
        color="standard"
        value={tableSwitch}
        exclusive
        onChange={handleChangeToggle}
      >
        <ToggleButton
          value="CURRENT"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'CURRENT' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'CURRENT' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          Current Project
        </ToggleButton>
        <ToggleButton
          value="ALL"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'ALL' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'ALL' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          All Project
        </ToggleButton>
      </ToggleButtonGroup>
      <TableContainer sx={{ backgroundColor: 'white', minHeight: '200px' }}>
        {createAnalyseStatus === STATUS.PENDING && (
          <div className={classes.progressDiv}>
            <CircularProgress />
          </div>
        )}
        {createAnalyseStatus === STATUS.SUCESS && (
          <>
            <Table className={classes.tableContainer} aria-label="simple table">
              <TableHead>
                {headers.map((header) => (
                  <TableCellHead key={header}>{header}</TableCellHead>
                ))}
              </TableHead>
              <TableBody>
                {analyseData && analyseData.data && Object.keys(analyseData.data).length > 0 ? (
                  Object.entries(analyseData.data).map(([email, taskData]: [string, any]) => (
                    <TableRowItem key={email} row={{ email, ...taskData }} allocationType={allocationType} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} className={classes.noUsersMessage}>
                      <Error message={'There are no users added as of yet. You can add a user to see it here.'} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </>
        )}
      </TableContainer>
    </>
  );
};

export default DataInformationAllocationTable;
