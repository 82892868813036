import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { CircularProgress, TableHead, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useStyles } from '../../assets/styles/listTableStyle';

import Error from '../error/error';
import { TableCellHead } from '../table/tableCell';
import UnallocationTableRowItem from './unallocationTableRowItem';
import { useParams } from 'react-router-dom';
import { unallocateAnalyseBulkVendor } from '../../redux/slices/vendorList';

interface DataInformationTableProps {
    formDataArray: any;
}

const VendorBulkUnallocationTable: React.FC<DataInformationTableProps> = ({ formDataArray }) => {
    const classes = useStyles();
    const [tableSwitch, setTableSwitch] = useState("CURRENT");
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const analyseData = useSelector((state: RootState) => state.vendorList.analyse);
    // const createAnalyseStatus = useSelector((state: RootState) => state.vendorList.createAnalyseStatus)
    const unallocateAnalyseStatus = useSelector((state: RootState) => state.vendorList.unallocateAnalyseStatus)

    const headers = ["VENDOR NAME", "TOTAL AFTER THIS UNALLOCATED", "TOTAL TASKS ALLOCATED TILL NOW", "TOTAL ALLOCATED TASKS TO EXPERTS BY THIS VENDOR"]

    const handleChangeToggle = (event: any, table: string) => {
        if (table !== null) {
            setTableSwitch(table);
            const payload = {
                project_id: id,
                data: formDataArray,
                analyse_all: table === 'ALL',
            }
            dispatch(unallocateAnalyseBulkVendor(payload))
        }
    };

    return (
        <>
            <ToggleButtonGroup
                color="standard"
                value={tableSwitch}
                exclusive
                onChange={handleChangeToggle}
            >
                <ToggleButton
                    value="CURRENT"
                    style={{
                        border: 'none',
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        color: tableSwitch === 'CURRENT' ? '#03BBD2' : '#6C6C6C',
                        borderBottom: tableSwitch === 'CURRENT' ? '3px solid #03BBD2' : 'none',
                        backgroundColor: 'inherit',
                        textTransform: 'none'
                    }}
                >
                    Current Project
                </ToggleButton>
                <ToggleButton
                    value="ALL"
                    style={{
                        border: 'none',
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        color: tableSwitch === 'ALL' ? '#03BBD2' : '#6C6C6C',
                        borderBottom: tableSwitch === 'ALL' ? '3px solid #03BBD2' : 'none',
                        backgroundColor: 'inherit',
                        textTransform: 'none'
                    }}
                >
                    All Project
                </ToggleButton>
            </ToggleButtonGroup>
            <TableContainer sx={{ backgroundColor: 'white', minHeight: '200px' }}>
                {unallocateAnalyseStatus === STATUS.PENDING && (
                    <div className={classes.progressDiv}>
                        <CircularProgress />
                    </div>
                )}
                {unallocateAnalyseStatus === STATUS.SUCESS && (
                    <>
                        <Table className={classes.tableContainer} aria-label="simple table">
                            <TableHead>
                                {headers.map((header) => (
                                    <TableCellHead key={header}>{header}</TableCellHead>
                                ))}
                            </TableHead>
                            <TableBody>
                                {analyseData && analyseData.length > 0 ? (
                                    analyseData.map((row: any) => (
                                        <>
                                            <UnallocationTableRowItem
                                                key={row.id}
                                                row={row}
                                            />
                                        </>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} className={classes.noUsersMessage}>
                                            <Error message={'There are no users added as of yet. You can add a user to see it here.'} />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </>
                )}
            </TableContainer>
        </>
    );

}

export default VendorBulkUnallocationTable;