import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Autocomplete, Divider, FormControl, Paper, Tab, Tabs, TextField } from '@mui/material';
import { AppDispatch } from '../../redux/store/appStore';
import { useDispatch } from 'react-redux';
import DashboardHeading from '../../components/dashboard/dashboardHeading';
import { getMetricsAction, getProjectWiseAnalyticsAction, getPseudoNameWiseAnalyticsAction, getTaskWiseAnalyticsAction, getVendorWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard';
import debounce from 'lodash.debounce';
import Overview from '../../components/AllDashboardTabs/Overview';
import TaskingOverview from '../../components/AllDashboardTabs/TaskingOverview';
import ReviewingOverview from '../../components/AllDashboardTabs/ReviewingOverview';
import ActiveProjectsOverview from '../../components/AllDashboardTabs/ActiveProjectsOverview';
import ActiveProjectVendorList from '../../components/Active Vendor List/ActiveProjectVendorList';
import ExpertWiseOverview from '../../components/AllDashboardTabs/ExpertWiseOverview';

const DashboardMain = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [itemsPerPage] = useState<number>(10);
  const [currentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState('overview');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    // dispatch(getAnalytics({ project_id: "", type: "all_project" }))
    dispatch(getMetricsAction({ association_type: "COMPANY" }))
    dispatch(getPseudoNameWiseAnalyticsAction())
    dispatch(getProjectWiseAnalyticsAction({ association_type: "COMPANY" }))
    dispatch(getTaskWiseAnalyticsAction({ allocation_type: "REVIEW" }));
    dispatch(getVendorWiseAnalyticsAction({ page: currentPage, pageSize: itemsPerPage as number, association_type: "VENDOR" }))

  }, [dispatch])

  return (

    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <DashboardHeading />
      </Box>
      <Divider />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '1rem' }}>
        {/* Tabs */}
        <Paper
          sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            padding: '1rem',
            mb: '1rem',
            boxShadow: 2,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="user tabs"
            sx={{
              mb: 2, borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: '0 2px 2px 2px',
              padding: '0px, 24px, 0px, 24px',
              
            }}
          >
            <Tab sx={{fontWeight:selectedTab === 'overview'? '600': '400'}} value="overview" label="Overview" />
            <Tab sx={{fontWeight:selectedTab === 'taskingOverview'?'600': '400'}} value="taskingOverview" label="tasking" />
            <Tab sx={{fontWeight:selectedTab === 'reviewOverview'?'600':'400'}} value="reviewOverview" label="review" />
            <Tab sx={{fontWeight:selectedTab === 'activeProjectOverview'?'600':'400'}} value="activeProjectOverview" label="active Project" />
            <Tab sx={{fontWeight:selectedTab === "activeVendorOverview"?'600':'400'}} value="activeVendorOverview" label="active Vendor" />
            <Tab sx={{fontWeight:selectedTab === "allexperts"?'600':'400'}} value="allexperts" label="All Experts" />
          </Tabs>

          {/* Tab Content */}
          {selectedTab === 'overview' && (<Overview />)}
          {selectedTab === 'taskingOverview' && <TaskingOverview />}
          {selectedTab === 'activeProjectOverview' && <ActiveProjectsOverview />}
          {selectedTab === 'reviewOverview' && <ReviewingOverview />}
          {selectedTab === "activeVendorOverview" && <ActiveProjectVendorList />}
          {selectedTab === "allexperts" && <ExpertWiseOverview/>}
        </Paper>
      </Box>
    </div>
  );
};
interface PaginationEntriesProps {
  itemsPerPage: number;
  onChangeRowsPerPage: any;
  handleOnChange?: any
}


export const ActiveProjectPaginationEntries: React.FC<PaginationEntriesProps> = ({ itemsPerPage, onChangeRowsPerPage, handleOnChange }) => {
  const [isDebounce, setIsDebounce] = useState(false)
  const data = [20, 50, 100, 500]

  const debouncedResults = useMemo(() => {
    setIsDebounce(true)
    return debounce((e) => onChangeRowsPerPage(e), 500)

  }, [onChangeRowsPerPage]);

  useEffect(() => {
    return () => {
      setIsDebounce(false)
      debouncedResults.cancel();
    };
  });


  return (
    <div style={{ display: 'flex', marginRight: '20px', alignItems: 'center' }}>
      <span style={{ paddingTop: '0px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D', marginBottom: '0px' }}>Show</span>
      <FormControl sx={{ m: 1, minWidth: 10 }}>

        <Autocomplete
          // disablePortal
          id="combo-box-demo"
          options={data}
          value={itemsPerPage}
          disabled={isDebounce}
          sx={{ width: 110 }}
          size='small'
          renderInput={(params: any) => (
            <TextField
              type='number'
              {...params}
              // onChange={(e:any) =>  debouncedResults(e.target.value)}
              onChange={(e: any) => debouncedResults(e.target.value)}

              sx={{
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                }
              }}
            />
          )}
          onChange={(e: any, newValue: any) => debouncedResults(newValue)}

        // open={data.includes(itemsPerPage)}
        />
      </FormControl>

      <span style={{ paddingTop: '0px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D', marginBottom: '0px' }}>Rows</span>
    </div>
  )
}

export default DashboardMain;
